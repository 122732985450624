import { BsGrid1X2 } from 'react-icons/bs';
import { FaList } from 'react-icons/fa';
import { FaCartShopping } from 'react-icons/fa6';
import { IoGridOutline } from 'react-icons/io5';
import { MdSmartScreen } from 'react-icons/md';
import { TbCarouselHorizontal } from 'react-icons/tb';
import { TfiLayoutSlider } from 'react-icons/tfi';
import styled from 'styled-components';
import FlexContainer from '../../shared/components/layouts/flex-container';
import WidgetCard from './components/WidgetCard';

const Wrapper = styled.div`
  padding: 40px 30px 150px;
  margin-top: -15px;
  background-color: rgb(251, 252, 255);

  .page-desc {
    margin-bottom: 30px;
  }
`;

const Title = styled.h1`
  font-size: 25px;
  margin: 0 0 10px;
  color: rgb(0, 0, 0);
  line-height: 30px;
`;

const WidgetsList = styled(FlexContainer)`
  flex-wrap: wrap;
  /* max-width: 1200px; */
  align-items: stretch;
`;

export default function Widgets() {
  return (
    <Wrapper>
      <Title>Widgets</Title>
      <p className="page-desc">
        Choose the type of widget you want to install.
      </p>

      <WidgetsList gap="25px" justifyContent="flex-start">
        <WidgetCard
          isHot
          title="Carousel slider"
          key="slider-widget"
          url="/widgets/slider"
          demoUrl="https://www.dev-site-5x9523.wix-development-sites.org/copy-of-grid"
          icon={<TfiLayoutSlider size={30} />}
          description="Great for showcasing a handful of reviews, this widget easily integrates into any page section. Users can navigate reviews effortlessly using left or right arrows"
        />

        <WidgetCard
          title="Carousel marquee"
          key="marquee-widget"
          url="/widgets/marquee"
          demoUrl="https://www.dev-site-5x9523.wix-development-sites.org/copy-of-slider"
          icon={<TbCarouselHorizontal size={30} />}
          description="
          Great for concise review displays, this widget autoscrolls within page sections. Encourages users to link to a page with expanded review options using Masonry or Grid widgets."
        />
        <WidgetCard
          title="Masonry"
          key="masonry-widget"
          url="/widgets/masonry"
          demoUrl="https://www.dev-site-5x9523.wix-development-sites.org/"
          icon={<BsGrid1X2 size={30} />}
          description='Great for a stunning "Wall of Love" with review pictures. Unique card heights for a masonry-style layout.'
        />

        <WidgetCard
          isHot
          title="Grid"
          key="grid-widget"
          url="/widgets/grid"
          demoUrl="https://www.dev-site-5x9523.wix-development-sites.org/grid-1"
          icon={<IoGridOutline size={30} />}
          description='Great for a beautiful "Wall of Love." Displays reviews without previewing pictures, creating a clean grid-style layout with uniform card heights.'
        />

        <WidgetCard
          isHot
          title="List"
          key="list-widget"
          url="/widgets/list"
          demoUrl="https://www.dev-site-5x9523.wix-development-sites.org/list-widget"
          icon={<FaList size={30} />}
          description='
          Great for showcasing a beautiful "Wall of Love," this widget previews review pictures and presents each review distinctly in a clean list format.'
        />

        <WidgetCard
          title="Modal widget"
          key="modal-widget"
          url="/widgets/modal-widget"
          demoUrl="https://www.dev-site-5x9523.wix-development-sites.org/"
          icon={<MdSmartScreen size={30} />}
          description="The Modal widget includes a side-tab that loads reviews in a modal when clicked. It's visible on all website pages."
        />

        <WidgetCard
          title="Product widget"
          key="product-widget"
          url="/widgets/product"
          demoUrl="https://www.dev-site-5x9523.wix-development-sites.org/product-page/i-m-a-product-2"
          icon={<FaCartShopping size={30} />}
          className="last-item"
          description="The Product widget is designed for product pages, seamlessly integrating with ecommerce-enabled websites."
        />
      </WidgetsList>
    </Wrapper>
  );
}
