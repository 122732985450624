import { unwrapResult } from '@reduxjs/toolkit';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import Icon from '@rsuite/icons/lib/Icon';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FaCheck, FaSpinner } from 'react-icons/fa';
import { RxCross2 } from 'react-icons/rx';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, ButtonToolbar, Form, Input, InputGroup, Schema } from 'rsuite';
import styled from 'styled-components';
import ReviewsJetCrownLogo from '../../../shared/components/ReviewsJetCrownLogo';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../shared/hooks/useNotification';
import useQuery from '../../../shared/hooks/useQuery';
import { Loading } from '../../../shared/types';
import { useAppDispatch, useAppSelector } from '../../../store';
import { ReviewsJetPlatform } from '../../product/types';
import {
  createAccount,
  validateRedemptionCode,
} from '../../userData/redux/action';
import { resetRedemptionCodeValidation } from '../../userData/redux/userData.slice';
import {
  AuthForm,
  AuthFormGroup,
  AuthIconWrapper,
  AuthLabel,
  AuthTextField,
  AuthTitle,
} from '../components';

const AppSumo = styled.img`
  height: 20px;
`;
const LoginButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;
`;

const LoginButton = styled.a`
  display: flex;
  gap: 5px;
  border: 1px solid #e5e5ea;
  border-radius: 6px;
  padding: 0 15px;
  align-items: center;
  color: #000;
  font-size: 15px;
  justify-content: center;

  &:hover {
    background-color: #000;
    color: #fff;
  }
`;
const RedemptionCodeStatus = styled(FlexContainer)`
  margin-right: 10px;
`;
const LogoCrown = styled.img`
  height: 50px;
  /* box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1); */
  border-radius: 100%;
  /* border: 2px solid #eaeaea; */
`;

const Container = styled(FlexContainer)`
  max-width: 1195px;
  margin: 100px 64px;
  background-color: #fff;
  border-radius: 5px;

  @media (max-width: 530px) {
    max-width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0px;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  padding: 20px 40px;
  max-width: 500px;
  /* border-right: 1px solid #e4e4e4; */
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  max-height: 90%;
  overflow: auto;

  @media (max-width: 530px) {
    max-width: 100%;
    box-shadow: none;
  }
`;
const LogosSeparator = styled.span`
  display: block;
  margin: 0 10px;
  font-size: 20px;
`;
const StyledFlexContainer = styled(FlexContainer)`
  border-top: 1px solid #eeeeee;
  /* border-bottom: 1px solid #eeeeee; */
  padding: 10px 0;
  /* min-width: 350px; */
`;
const CarouselWrapper = styled.div`
  height: 730px;
  flex: 2;
  padding: 5px;
`;
const Item = styled.div<{ background: string }>`
  width: 100%;
  height: 100%;
  background-image: url(${({ background }) => background});
  background-repeat: no-repeat;
  /* border-radius: 10px; */
  background-size: cover;
`;
const { StringType } = Schema.Types;

const model = Schema.Model({
  fullName: StringType().isRequired('Please enter your full name'),
  email: StringType()
    .isEmail('Please enter a valid email address.')
    .isRequired('This field is required.'),
  websiteUrl: StringType()
    .isRequired('This field is required.')
    .isURL('Please enter a valid website URL'),
  password: StringType()
    .isRequired('This field is required.')
    .addRule((password) => {
      let strength = 0;

      if (password.match(/[a-z]+/)) {
        strength += 1;
      }

      if (password.match(/[0-9]+/)) {
        strength += 1;
      }

      if (password.length >= 5) {
        strength += 1;
      }

      if (strength < 3) {
        return false;
      }

      return true;
    }, 'Password should contain at least a letter and a number.'),
  verifyPassword: StringType()
    .addRule((value, data) => {
      if (value !== data.password) {
        return false;
      }

      return true;
    }, 'The two passwords do not match')
    .isRequired('This field is required.'),
});

const Register = () => {
  const query = useQuery();
  const { showErrorToast } = useNotification();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [captchaToken, setCaptchaToken] = useState('');
  const formRef = useRef<{ check: () => void }>(null);
  const [visible, setVisible] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  // const [validateRedemptionCode, setValidateRedemptionCode] = useState(false);
  const { code } = useParams<{ code: string }>();
  const [redemptionCode, setRedemptionCode] = useState(code);
  // const [validating, setValidating] = useState(Loading.INITIAL);

  const {
    validate: { loading: validating },
    createAccount: { loading },
  } = useAppSelector((state) => state.userData);

  const [formValue, setFormValue] = useState({
    email: query.get('email') ?? '',
    siteId: query.get('site') ?? '',
    userId: query.get('id') ?? '',
    platform: query.get('platform') ?? ReviewsJetPlatform.OTHERS,
    fullName: query.get('fullname') ?? '',
    password: '',
    websiteUrl: '',
    verifyPassword: '',
    source: pathname.includes('appsumo') ? 'appsumo' : null,
  });

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  // const verifyCaptcha = async () => {
  //   const token = await captchaRef.current.getResponse();
  //   setCaptchaToken(token);
  // };

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      if (!formRef.current?.check() || validating === Loading.PENDING) {
        return;
      }

      // if (captchaRef?.current) {
      //   captchaRef.current.reset();
      // }

      let payload = {
        ...formValue,
        token: captchaToken,
      };

      if (redemptionCode && validating === Loading.SUCCESS) {
        // @ts-ignore
        payload = { ...payload, redemptionCode };
      }

      dispatch(createAccount(payload))
        .then(unwrapResult)
        .then(({ error, errorMessage, confirmationCode }) => {
          if (error) {
            showErrorToast(errorMessage);
          } else {
            navigate(`/account-creation-success/${confirmationCode}`);
          }
        })
        .catch((e) => {
          showErrorToast('Something went wrong. Please try it again.');
        });
    },
    [validating, redemptionCode, formValue]
  );

  const validate = async (value: string) => {
    if (!value) {
      return dispatch(resetRedemptionCodeValidation());
    }
    setRedemptionCode(value);
    dispatch(validateRedemptionCode(value))
      .then(unwrapResult)
      .catch(() => {});
  };

  const handleValidateRedemption = useRef(debounce(validate, 500)).current;

  useEffect(() => {
    if (code) {
      setRedemptionCode(code);
      validate(code);
    }
  }, [code]);

  return (
    <Container justifyContent="space-between" alignItems="stretch">
      <FormWrapper>
        <FlexContainer
          justifyContent="flex-start"
          alignItems="center"
          gap="10px"
        >
          <a href="https://www.reviewsjet.com">
            <ReviewsJetCrownLogo />
          </a>
          {pathname.includes('appsumo') && (
            <>
              <LogosSeparator>|</LogosSeparator>
              <AppSumo alt="Appsumo" src="/images/appsumo.svg" />
            </>
          )}
        </FlexContainer>
        <AuthTitle>Register</AuthTitle>

        {/* {!pathname.includes('appsumo') && (
          <>
            <LoginButtonWrapper>
              <LoginButton
                href="https://www.wix.com/app-market/reviewsjet"
                target="_blank"
              >
                <WixIcon size={40} /> | Register with Wix
              </LoginButton>
            </LoginButtonWrapper>

            <Divider>or</Divider>
          </>
        )} */}

        <AuthForm
          fluid
          ref={formRef}
          onChange={setFormValue}
          formValue={formValue}
          model={model}
        >
          <FlexContainer gap="10px" justifyContent="space-between">
            <AuthFormGroup controlId="fullName" $marginBotton="0px">
              <AuthLabel>Full Name</AuthLabel>
              <AuthTextField
                name="fullName"
                type="text"
                autoComplete="off"
                placeholder="John Doe"
              />
            </AuthFormGroup>
            <AuthFormGroup controlId="password">
              <AuthLabel>Email</AuthLabel>
              <AuthTextField
                name="email"
                type="email"
                autoComplete="off"
                placeholder="john@doe.com"
              />
            </AuthFormGroup>
          </FlexContainer>
          <AuthFormGroup controlId="websiteUrl">
            <AuthLabel>Website Url</AuthLabel>
            <AuthTextField
              name="websiteUrl"
              type="text"
              autoComplete="off"
              placeholder="https://johndoe.com"
            />
          </AuthFormGroup>
          <AuthFormGroup controlId="password">
            <AuthIconWrapper onClick={toggleVisibility}>
              {visible ? <EyeIcon /> : <EyeSlashIcon />}
            </AuthIconWrapper>
            <AuthLabel>Password</AuthLabel>
            <AuthTextField
              isPassword
              name="password"
              type={visible ? 'text' : 'password'}
              autoComplete="off"
              placeholder={visible ? 'Enter password' : '*************'}
            />
          </AuthFormGroup>

          <AuthFormGroup controlId="password">
            <AuthLabel>Confirm Password</AuthLabel>
            <AuthTextField
              name="verifyPassword"
              type={visible ? 'text' : 'password'}
              autoComplete="off"
              placeholder={visible ? 'Enter password' : '*************'}
            />
          </AuthFormGroup>

          {/* <div className=""> */}
          {pathname.includes('appsumo') && (
            <AuthFormGroup
              controlId="redemptionCode"
              className="redemption-code"
            >
              <AuthLabel>Redemption Code</AuthLabel>
              <InputGroup
                inside
                style={{
                  borderColor: ` ${
                    validating == Loading.ERROR
                      ? 'crimson'
                      : validating == Loading.SUCCESS
                      ? 'green'
                      : ''
                  }`,
                }}
              >
                <Input
                  type="text"
                  placeholder="Enter your redemption code if you have one."
                  defaultValue={redemptionCode}
                  onChange={handleValidateRedemption}
                />
                <RedemptionCodeStatus>
                  {validating === Loading.PENDING && (
                    <>
                      {/* @ts-ignore */}
                      <Icon as={FaSpinner} size="18px" pulse />
                    </>
                  )}
                  {validating == Loading.ERROR && (
                    <RxCross2 color="crimson" size={20} />
                  )}
                  {validating == Loading.SUCCESS && <FaCheck color="green" />}
                </RedemptionCodeStatus>
              </InputGroup>
            </AuthFormGroup>
          )}

          {/* </div> */}

          {/* <Form.Group>
          <Reaptcha
            sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY ?? ''}
            theme="light"
            size="normal"
            badge="bottomleft"
            ref={captchaRef}
            onVerify={verifyCaptcha}
            onError={() => {
              setCaptchaToken('');
            }}
            onExpire={() => {
              setCaptchaToken('');
            }}
          />
        </Form.Group> */}
          <Form.Group>
            <ButtonToolbar>
              <Button
                block
                size="lg"
                color="violet"
                appearance={loading === Loading.PENDING ? 'default' : 'primary'}
                type="submit"
                onClick={handleSubmit}
                disabled={loading === Loading.PENDING || isValidForm}
              >
                {loading === Loading.PENDING
                  ? 'Please wait...'
                  : 'Create account'}
              </Button>
            </ButtonToolbar>
          </Form.Group>

          {!pathname.includes('appsumo') && (
            <StyledFlexContainer gap="10px">
              Already have an account?
              <Link to="/login">Sign in</Link>
            </StyledFlexContainer>
          )}

          <div style={{ display: 'none' }}>
            <AuthTextField name="siteId" type="hidden" />
            <AuthTextField name="userId" type="hidden" />
            <AuthTextField name="platform" type="hidden" />
            <AuthTextField
              name="source"
              type="hidden"
              value={pathname.includes('appsumo') ? 'appsumo' : null}
            />
          </div>
        </AuthForm>
      </FormWrapper>
      {/* <CarouselWrapper>
        <Carousel
          autoplay
          shape="bar"
          autoplayInterval={5000}
          className="signup-slider"
        >
          <Item background="/images/slider-1.png" />
          <Item background="/images/slider-2.png" />
          <Item background="/images/slider-3.png" />
        </Carousel>
      </CarouselWrapper> */}
    </Container>
  );
};

export default Register;
