import { useTranslation } from 'react-i18next';
import { Button, List } from 'rsuite';
import styled, { css } from 'styled-components';
import StyledText from '../../../../shared/components/styled-text';

const ProductImage = styled.img`
  width: 50px;
  height: 50px;
  background: #9e9e9e;
  border-radius: 5px;
  margin-right: 10px;
  padding: 1px;
`;
const ProductName = styled(StyledText)`
  font-weight: 700;
  line-height: 19px;
`;
const PriceText = styled(StyledText)`
  line-height: 19px;
  text-align: left;
  font-size: 13px;
  color: #676767;
  margin-top: 5px;
`;
const StyledButton = styled(Button)`
  display: none;
`;
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 460px;
`;
const TextWrapper = styled.div``;

const StyledList = styled(List)`
  cursor: pointer;
`;

const StyledListItem = styled(List.Item)<{ disabled?: boolean }>`
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
  align-items: center;
  &:hover {
    button {
      display: block;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.7;
    `}
`;

interface Props {
  name: string;
  imageUrl: string;
  price: string;
  onSelect: () => void;
  disabled?: boolean;
}

export const ProductItem = ({
  disabled,
  name,
  imageUrl,
  price,
  onSelect,
}: Props) => {
  const { t } = useTranslation('common');

  return (
    <StyledList disabled={disabled} hover>
      <StyledListItem onClick={onSelect}>
        <LeftWrapper>
          <ProductImage src={imageUrl} />
          <TextWrapper>
            <ProductName>{name}</ProductName>
            <PriceText>{price}</PriceText>
          </TextWrapper>
        </LeftWrapper>
        <StyledButton onClick={onSelect} appearance="ghost" size="sm">
          {t('reviews.assign-to-product-modal.select')}
        </StyledButton>
      </StyledListItem>
    </StyledList>
  );
};
