import { useCallback, useRef, useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import styled from 'styled-components';
import useClickOutside from '../../hooks/useClickOutside';
import FlexContainer from '../layouts/flex-container';

type Props = {
  color: string;
  changeColor: (value: string) => void;
};

const Picker = styled.div`
  position: relative;
`;
const Swatch = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 8px;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const Popover = styled.div`
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  z-index: 99999999999;
`;

const ColorInput = styled(HexColorInput)`
  border-width: 0;
  text-transform: uppercase;
  width: 100px;
  color: #858585;
  font-weight: bold;
  padding-left: 5px;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

const RelativeContainer = styled.div`
  position: relative;
  span {
    color: #858585;
    font-weight: bold;
    padding-left: 10px;
  }
`;

export const ColorPicker = ({ color, changeColor }: Props) => {
  const popover = useRef() as any;
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <Picker>
      <FlexContainer>
        <Swatch
          className="swatch"
          style={{ backgroundColor: color }}
          onClick={() => toggle(true)}
          role="presentation"
        />
        <RelativeContainer>
          <span>#</span>
          <ColorInput color={color} onChange={changeColor} />
        </RelativeContainer>
      </FlexContainer>

      {isOpen && (
        <Popover ref={popover}>
          <HexColorPicker color={color} onChange={changeColor} />
        </Popover>
      )}
    </Picker>
  );
};

export default ColorPicker;
