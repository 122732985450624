import { capitalize } from 'lodash';
import { useState } from 'react';
import { FaCircleInfo } from 'react-icons/fa6';
import { IoColorPaletteOutline, IoSettingsOutline } from 'react-icons/io5';
import { Button, ButtonToolbar, Divider, Drawer, Message } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import AppModal from '../../../../shared/components/modal';
import useModal from '../../../../shared/components/modal/useModal';
import useInstallation, {
  Widget,
} from '../../../../shared/hooks/useInstallation';
import { WidgetType } from '../../../../shared/types';
import { useAppSelector } from '../../../../store';
import { ReviewsJetPlatform } from '../../../product/types';
import GoDaddyCarouselWidget from '../../../tutorials/components/godaddy/carousel-widget';
import PixpaCarouselWidget from '../../../tutorials/components/pixpa/carousel-widget';
import ShopifyCarouselWidget from '../../../tutorials/components/shopify/carousel-widget';
import SquarespaceCarouselWidget from '../../../tutorials/components/squarespace/carousel-widget';
import WebflowCarouselWidget from '../../../tutorials/components/webflow/carousel-widget';
import WixCarouselWidget from '../../../tutorials/components/wix/carousel-widget';
import WordpressCarouselWidget from '../../../tutorials/components/wordpress/carousel-widget';
import { accountSelector } from '../../../userData/redux/userData.slice';
import HowToInstall from '../HowToInstall';
import MoreCustomization from './AdvancedSettings';
import BasicSettings from './BasicSettings';
import CarouselPreview from './CarouselPreview';

const Wrapper = styled.div`
  max-width: 950px;
  margin: auto;
`;

const CodeWrapper = styled.div`
  max-width: 100%;
  pre {
    margin: 0 0 20px !important;
    padding: 10px !important;
  }
`;

const StyledButtonToolbar = styled(ButtonToolbar)``;

const CarouselWidgetType = ({ widgetType }: { widgetType: WidgetType }) => {
  const { account } = useAppSelector(accountSelector);
  const [showMoreConfig, setShowMoreConfig] = useState(false);
  const { installationCode } = useInstallation(Widget.CAROUSEL);
  const { selectedSite } = account ?? {};
  const { _id: siteId, platform } = selectedSite ?? {};
  const embedCode = `${installationCode}
<reviewsjet-slider id="${siteId}" count="3" platform="others"></reviewsjet-slider>`;

  const { handleCloseModal, handleOpenModal, showModal } = useModal();
  const [open, setOpen] = useState<ReviewsJetPlatform | null>(null);

  return (
    <>
      <Wrapper>
        <CodeWrapper>
          <Message bordered>
            <FlexContainer
              gap="20px"
              justifyContent="space-between"
              style={{ width: '100%' }}
            >
              <div>
                To learn how to install the Carousel Slider Widget, please click
                the following button
              </div>
              <div>
                <Button
                  block
                  appearance="primary"
                  color="violet"
                  onClick={() => {
                    if (platform === ReviewsJetPlatform.WIX) {
                      setOpen(ReviewsJetPlatform.WIX);
                    } else if (platform === ReviewsJetPlatform.SHOPIFY) {
                      setOpen(ReviewsJetPlatform.SHOPIFY);
                    } else {
                      handleOpenModal();
                    }
                  }}
                >
                  <FlexContainer gap="10px">
                    <FaCircleInfo />
                    <span>How to install?</span>
                  </FlexContainer>
                </Button>
              </div>
            </FlexContainer>
          </Message>
          <Divider />

          <StyledButtonToolbar>
            <Button
              color="blue"
              appearance={showMoreConfig ? 'link' : 'default'}
              onClick={() => setShowMoreConfig(false)}
            >
              <FlexContainer gap="5px" justifyContent="flex-start">
                <IoSettingsOutline size={20} />
                Basic Settings
              </FlexContainer>
            </Button>
            <Button
              color="blue"
              appearance={showMoreConfig ? 'default' : 'link'}
              onClick={() => setShowMoreConfig(true)}
            >
              <FlexContainer gap="5px" justifyContent="flex-start">
                <IoColorPaletteOutline size={20} />
                Advanced Settings
              </FlexContainer>
            </Button>
          </StyledButtonToolbar>

          {showMoreConfig ? (
            <MoreCustomization />
          ) : (
            <BasicSettings widgetType={widgetType} />
          )}

          <Divider />
          <CarouselPreview />
        </CodeWrapper>
      </Wrapper>
      <AppModal
        size="sm"
        shouldOpenModal={showModal}
        onModalClose={handleCloseModal}
        body={
          <HowToInstall
            embedCode={embedCode}
            onClose={handleCloseModal}
            openTutorial={(platform: ReviewsJetPlatform) => setOpen(platform)}
          />
        }
      />
      <Drawer
        size="lg"
        open={!!open}
        onClose={() => setOpen(null)}
        className="tutorial-drawer"
      >
        <Drawer.Header>
          <Drawer.Title>{`How To Install the Carousel Widget on ${capitalize(
            open ?? ''
          )}`}</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setOpen(null)}>Close</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          {open === ReviewsJetPlatform.WORDPRESS && (
            <WordpressCarouselWidget widgetType={widgetType} />
          )}
          {open === ReviewsJetPlatform.WIX && (
            <WixCarouselWidget widgetType={widgetType} />
          )}
          {open === ReviewsJetPlatform.WEBFLOW && (
            <WebflowCarouselWidget widgetType={widgetType} />
          )}
          {open === ReviewsJetPlatform.SQUARESPACE && (
            <SquarespaceCarouselWidget widgetType={widgetType} />
          )}
          {open === ReviewsJetPlatform.GO_DADDY && (
            <GoDaddyCarouselWidget widgetType={widgetType} />
          )}

          {open === ReviewsJetPlatform.PIXPA && (
            <PixpaCarouselWidget widgetType={widgetType} />
          )}
          {open === ReviewsJetPlatform.SHOPIFY && (
            <ShopifyCarouselWidget widgetType={widgetType} count={3} />
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default CarouselWidgetType;
