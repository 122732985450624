import styled from 'styled-components';

export type FlexContainerJustifyContent =
  | 'center'
  | 'space-between'
  | 'flex-start'
  | 'flex-end'
  | 'space-around'
  | 'space-evenly'
  | 'stretch';

export interface FlexContainerProps {
  stack?: boolean;
  gap?: string;
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  justifyContent?: FlexContainerJustifyContent;
}

const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  position: relative;
  flex-direction: ${({ stack }) => (stack ? 'column' : 'row')};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  gap: ${({ gap }) => gap};
`;

export default FlexContainer;
