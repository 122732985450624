import { createAsyncThunk } from '@reduxjs/toolkit';
import Http from '../../../services/http';
import { Loading } from '../../../shared/types';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { RootState } from '../../../store';
import {
  ReviewRequestStatus,
  SendReviewRequestTestEmailPayload,
} from '../../review/types';
import { RequestPageType, ReviewRequestType } from './campaign.slice';

type FetchReviewRequestPayload = {
  sort?: 'desc' | 'asc';
  limit?: number;
  page?: number;
};

type FetchCampaignPayload = FetchReviewRequestPayload & {
  reviewRequestId: string;
};

const http = new Http();

type ReviewRequestContact = {
  name: string;
  email: string;
  product?: {
    id: string;
    title: string;
    price: string;
    slug: string;
  };
};

type ReviewRequestContent = {
  title: string;
  message: string;
};

type SaveReviewRequestPayload = {
  title: string;
  senderEmail: string;
  reviewType: RequestPageType | null;
  incentiveId: string;
  contacts: ReviewRequestContact[];
  firstRequest: ReviewRequestContent;
  secondRequest: ReviewRequestContent & {
    enabled: boolean;
    whenToSend: string;
  };
  requestType: ReviewRequestType | null;
};

export const saveReviewRequest = createAsyncThunk<
  any,
  SaveReviewRequestPayload,
  { rejectValue: string; state: RootState }
>('review/review-request', async (body, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/reviews-request/create`,
      body
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const fetchCampaigns = createAsyncThunk<
  any,
  FetchCampaignPayload,
  { rejectValue: string; state: RootState }
>(
  'campaigns/all',
  async ({ reviewRequestId, page }, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/reviews-request/list/${reviewRequestId}?page=${page ?? 1}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        campaign: {
          campaigns: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const deleteReviewsRequest = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>('reviews-request/delete', async (reviewsRequestId, { rejectWithValue }) => {
  try {
    const response = await http.delete(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/reviews-request/delete/${reviewsRequestId}`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const fetchReviewRequests = createAsyncThunk<
  any,
  FetchReviewRequestPayload,
  { rejectValue: string; state: RootState }
>(
  'review/request/all',
  async ({ page }, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/reviews-request/list?page=${page}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        campaign: {
          reviewRequests: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const sendReviewRequestTestEmail = createAsyncThunk<
  any,
  SendReviewRequestTestEmailPayload,
  { rejectValue: string; state: RootState }
>('campaign/send-test-email', async (body, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/reviews-request/send-test`,
      body
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const sendReviewRequestEmail = createAsyncThunk<
  any,
  { reviewRequestId: string },
  { rejectValue: string; state: RootState }
>('campaign/send-email', async (body, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/reviews-request/send`,
      body
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const getTotalCampaignsThisMonth = createAsyncThunk<
  any,
  void,
  { rejectValue: string; state: RootState }
>(
  'campaign/get-total-this-month',
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/reviews-request/total-sent`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        campaign: {
          totalCampaignsThisMonth: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const toggleReviewRequest = createAsyncThunk<
  any,
  { id: string; action: ReviewRequestStatus },
  { rejectValue: string; state: RootState }
>(
  'review-request/toggle',
  async ({ id, action }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/reviews-request/toggle`,
        { id, action }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        campaign: { toggleReviewRequestLoading: loading },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const getAutomaticReviewRequest = createAsyncThunk<
  any,
  void,
  { rejectValue: string; state: RootState }
>(
  'review-request/get-automatic-campaign',
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/reviews-request/get-automatic-campaign`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        campaign: { toggleReviewRequestLoading: loading },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const getWixOrders = createAsyncThunk<
  any,
  { getProducts: boolean; cursor: string | undefined },
  { rejectValue: string; state: RootState }
>('orders/wix', async ({ cursor, getProducts }, { rejectWithValue }) => {
  try {
    let url = `${removeTrailingSlash(
      process.env.REACT_APP_REVIEWS_JET_API!
    )}/wix/orders?getProducts=${getProducts}`;

    if (cursor) {
      url += `&cursor=${cursor}`;
    }
    const response = await http.get(url);
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const getShopifyOrders = createAsyncThunk<
  any,
  void,
  { rejectValue: string; state: RootState }
>('orders/shopify', async (_, { rejectWithValue }) => {
  try {
    const response = await http.get(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/shopify/orders`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});
