import { Checkbox, Divider, InputNumber, Toggle } from 'rsuite';
import { ValueType } from 'rsuite/esm/Checkbox';
import styled from 'styled-components';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import PremiumFeature from '../../../../shared/components/PremiumFeature';
import { SubscriptionPlan, WidgetType } from '../../../../shared/types';
import { GENERIC_UPGRADE_MESSAGE } from '../../../../shared/utils/getUpgradeMessage';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { toggleUpgradeModal } from '../../../../store/app.slice';
import { accountSelector } from '../../../userData/redux/userData.slice';
import FormRow from '../../components/form-row';
import useDispatchUpdateSiteWidgetSettings from '../../hooks/useDispatchUpdateSiteWidgetSettings';
import { settingSelector } from '../../redux/widgetSettings.slice';

/* eslint no-console: "warn" */

const BasicCustomization = styled.div`
  padding-top: 30px;
`;
const CheckboxesWrapper = styled.div`
  margin-left: -10px;
`;

const BasicSettings = ({ widgetType }: { widgetType: WidgetType }) => {
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(accountSelector);
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { dispatchUpdateSiteWidgetSettings } =
    useDispatchUpdateSiteWidgetSettings();
  const { selectedSite } = account ?? {};
  const { plan } = selectedSite || {};

  return (
    <BasicCustomization>
      <FormRow
        alignItems="center"
        justifyContent="flex-start"
        gap="50px"
        marginBottom="10px"
        style={{ height: 40 }}
      >
        <FlexContainer justifyContent="flex-start" gap="5px">
          Auto Play
          <Toggle
            size="md"
            checked={!!siteWidgetSettings?.carouselSliderWidget.autoplay}
            checkedChildren="Yes"
            unCheckedChildren="No"
            onClick={() => {
              dispatchUpdateSiteWidgetSettings(
                'autoplay',
                !siteWidgetSettings?.carouselSliderWidget.autoplay,
                'slider'
              );
            }}
          />
        </FlexContainer>
        {siteWidgetSettings?.carouselSliderWidget.autoplay && (
          <>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <span>Speed</span>
              <InputNumber
                type="number"
                min={2000}
                max={10000}
                size="sm"
                value={siteWidgetSettings?.carouselSliderWidget.autoPlaySpeed}
                onChange={(value) => {
                  dispatchUpdateSiteWidgetSettings(
                    'autoPlaySpeed',
                    value,
                    'slider'
                  );
                }}
                style={{ width: 100 }}
              />
            </FlexContainer>

            {/* <FlexContainer justifyContent="flex-start" gap="5px">
              Pause on hover
              <Toggle
                size="md"
                checked={
                  !!siteWidgetSettings?.carouselSliderWidget.pauseOnHover
                }
                checkedChildren="Yes"
                unCheckedChildren="No"
                onClick={() => {
                  dispatchUpdateSiteWidgetSettings(
                    'pauseOnHover',
                    !siteWidgetSettings?.carouselSliderWidget.pauseOnHover,
                    'slider'
                  );
                }}
              />
            </FlexContainer> */}
          </>
        )}
      </FormRow>

      <Divider />

      <CheckboxesWrapper>
        {plan === SubscriptionPlan.FREE && (
          <FormRow marginBottom="0" justifyContent="flex-start" gap="10px">
            <Checkbox
              readOnly
              onClick={() =>
                dispatch(toggleUpgradeModal(GENERIC_UPGRADE_MESSAGE))
              }
            >
              Remove ReviewsJet branding
            </Checkbox>
            <PremiumFeature />
          </FormRow>
        )}
        <FormRow marginBottom="0px">
          <Checkbox
            checked={siteWidgetSettings?.modalWidget.showReviewDate}
            onChange={(_?: ValueType, checked?: boolean) => {
              dispatchUpdateSiteWidgetSettings(
                'showReviewDate',
                checked,
                'modal'
              );
            }}
          >
            Show review date
          </Checkbox>
        </FormRow>
        <FormRow marginBottom="0px">
          <Checkbox
            checked={!siteWidgetSettings?.modalWidget.hideSourceIcon}
            onChange={(_?: ValueType, checked?: boolean) => {
              dispatchUpdateSiteWidgetSettings(
                'hideSourceIcon',
                !checked,
                'modal'
              );
            }}
          >
            Show review icons
          </Checkbox>
        </FormRow>
        <FormRow marginBottom="0">
          <Checkbox
            checked={!!siteWidgetSettings?.carouselSliderWidget.acceptReviews}
            onChange={(_?: ValueType, checked?: boolean) => {
              dispatchUpdateSiteWidgetSettings(
                'acceptReviews',
                checked,
                'slider'
              );
            }}
          >
            Enable review submission
          </Checkbox>
        </FormRow>

        <FormRow marginBottom="0">
          <Checkbox
            checked={
              !!siteWidgetSettings?.carouselSliderWidget.showOverallRating
            }
            onChange={(_?: ValueType, checked?: boolean) => {
              dispatchUpdateSiteWidgetSettings(
                'showOverallRating',
                checked,
                'slider'
              );
            }}
          >
            Display overral rating
          </Checkbox>
        </FormRow>
      </CheckboxesWrapper>
    </BasicCustomization>
  );
};

export default BasicSettings;
