import { useEffect, useState } from 'react';
import { Button, Message } from 'rsuite';
import styled from 'styled-components';
import { toggleFeedbackDrawer } from '../../features/userData/redux/userData.slice';
import { useAppDispatch } from '../../store';
import localStorage from '../utils/localStorage';

/* eslint no-console: "warn" */

const StyledMessage = styled(Message)`
  max-width: 890px;
  margin: 15px auto;

  span {
    display: block;
    margin-bottom: 10px;
  }
`;

export default function ContactSupport() {
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const hide = localStorage.get('hide-contact-support-message');

    if (hide) {
      setShow(false);
    }
  }, []);

  if (!show) {
    return null;
  }

  return (
    <StyledMessage
      bordered
      showIcon
      closable
      header="Need help installing widgets or importing reviews?"
      onClose={() => {
        localStorage.set('hide-contact-support-message', true);
      }}
    >
      <div>
        <span>
          Don't hesitate to reach out! Our team is here to assist you every step
          of the way. Contact us now and let's get started!
        </span>

        <Button
          color="violet"
          appearance="primary"
          onClick={() => dispatch(toggleFeedbackDrawer())}
        >
          Contact support
        </Button>
      </div>
    </StyledMessage>
  );
}
