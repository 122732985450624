import { createSlice } from '@reduxjs/toolkit';
import { Loading } from '../../../shared/types';
import { RootState } from '../../../store';
import { fetchPublicShareLinkSettings } from './actions';

export enum PublicShareLinkHttpResponseStatus {
  NOT_VALID_SITE_ID = 'NOT_VALID_SITE_ID',
  SUCCESS = 'SUCCESS',
}

type PublicShareLinkState = {
  loading: Loading;
  status: PublicShareLinkHttpResponseStatus | null;
};

const initialState: PublicShareLinkState = {
  loading: Loading.INITIAL,
  status: null,
};

const publicSharelink = createSlice({
  name: 'public-share-link',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPublicShareLinkSettings.pending, (state) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(
      fetchPublicShareLinkSettings.fulfilled,
      (state, { payload }) => {
        state.loading = Loading.SUCCESS;
        state.status = payload.status;
      }
    );
    builder.addCase(fetchPublicShareLinkSettings.rejected, (state) => {
      state.loading = Loading.ERROR;
    });
  },
});

export const publicShareLinkSelector = (state: RootState) =>
  state.publicShareLink;

export default publicSharelink.reducer;
