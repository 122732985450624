import { unwrapResult } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import hexRgb from 'hex-rgb';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import Preloader from '../../../../shared/components/Preloader';
import YelpIcon from '../../../../shared/components/YelpIcon';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import MovingParticules from '../../../../shared/components/moving-particules/MovingParticules';
import { useNotification } from '../../../../shared/hooks/useNotification';
import ReviewRating from '../../../../shared/svgs/ReviewRating';
import { Loading } from '../../../../shared/types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { updateWidgetsSettings } from '../../redux/action';
import {
  BackgroundType,
  BrandingSettings,
  CarouselWidgetSettings,
  ShadowSize,
  ShadowType,
  resetWidgetSettingsState,
  settingSelector,
} from '../../redux/widgetSettings.slice';

const BlockLabel = styled.label`
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
  color: #000;
`;

const PlatformIconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  bottom: 1px;
  right: -1px;
  background-color: #fff;
  border: 2px solid #fff;
`;

const Wrapper = styled.div`
  margin-bottom: 30px;
`;

const Reviewer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  img {
    width: 100%;
  }
`;

const PreviewSection = styled.div<{ $carouselWidget: CarouselWidgetSettings }>`
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #dedede;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to top, #ef4444, #7c3aed, #334155);
  /* background: ${({
    $carouselWidget: {
      backgroundColor,
      backgroundColorType,
      backgroundColorGradient,
    },
  }) => {
    if (backgroundColorType === BackgroundType.TRANSPARENT) {
      return 'transparent';
    } else if (backgroundColorType === BackgroundType.SOLID_COLOR) {
      return backgroundColor;
    } else if (backgroundColorType === BackgroundType.GRADIENT) {
      return `linear-gradient(to right, ${backgroundColorGradient});`;
    }
  }}; */
`;

const DiscardBtn = styled.div`
  padding: 5px 8px;
  border-radius: 5px;
  color: #333;
  font-size: 13px;
  cursor: pointer;
  background-color: #f5f5f5;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    background-color: rgb(219 216 216);
  }
`;

const SaveBtn = styled.div`
  padding: 5px 8px;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  cursor: pointer;
  background-color: #3498ff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    background-color: #166ec7;
    color: #fff;
  }
`;

/* eslint no-console: "warn" */

const WidgetCard = styled.div<{
  $carouselWidget: CarouselWidgetSettings;
  $branding: BrandingSettings;
}>`
  padding: 20px;
  position: relative;
  margin-top: 10px;
  max-width: 300px;
  margin: auto;
  min-height: 300px;
  font-family: ${({ $branding }) =>
    $branding.useMyWebsiteFontFamily ? 'auto' : $branding.fontFamily};
  border-radius: ${({ $carouselWidget: { borderRadius } }) => borderRadius};
  ${({ $carouselWidget: { showBorder, borderColor, borderWidth } }) => {
    if (showBorder) {
      return css`
        border: ${borderWidth}px solid ${borderColor};
      `;
    }
  }}
  /* box-shadow: ${({
    $carouselWidget: { shadowType, shadowColor, shadowSize },
  }) => {
    if (shadowType === ShadowType.NORMAL) {
      if (shadowSize === ShadowSize.SMALL) {
        return `0px 10px 15px -3px ${shadowColor}`;
      } else if (shadowSize === ShadowSize.MEDIUM) {
        return `0px 10px 15px 5px ${shadowColor}`;
      } else if (shadowSize === ShadowSize.LARGE) {
        return `0px 10px 15px 9px ${shadowColor}`;
      }
    } else if (shadowType === ShadowType.FOCUS) {
      if (shadowSize === ShadowSize.SMALL) {
        return `0px 10px 0 -3px ${shadowColor}`;
      } else if (shadowSize === ShadowSize.MEDIUM) {
        return `0px 10px 0 5px ${shadowColor}`;
      } else if (shadowSize === ShadowSize.LARGE) {
        return `0px 10px 0 9px ${shadowColor}`;
      }
    }
    return '';
  }}; */
  background-color: ${({
    $carouselWidget: { reviewCardColorOpacity, reviewCardColor },
  }) => {
    return hexRgb(reviewCardColor, {
      alpha: reviewCardColorOpacity / 100,
      format: 'css',
    });

    // if (reviewCardBackgroundType === BackgroundType.TRANSPARENT) {
    //   return 'transparent';
    // } else {
    //   return reviewCardColor;
    // }
  }};
  z-index: 2;
`;

const ReadMore = styled.span<{ color: string }>`
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  margin-left: 5px;
  color: ${({ color }) => color};
  &:hover {
    text-decoration: underline;
  }
`;

const ReviewerAvatar = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  text-align: center;
  position: relative;
`;
const ReviewerPic = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`;
const ReviewerNameDate = styled.div`
  text-align: center;
`;
const ReviewerName = styled.h4<{ color: string; $fontFamily: string }>`
  margin-bottom: 5px;
  font-weight: bold;
  margin-top: 5px;
  font-size: 18px;
  color: ${({ color }) => color};
  font-family: ${({ $fontFamily }) => $fontFamily};
`;
const ReviewerDate = styled.span<{ color: string; $fontFamily: string }>`
  font-size: 12px;
  color: ${({ color }) => color};
  display: block;
  margin-bottom: 10px;
  font-family: ${({ $fontFamily }) => $fontFamily};
`;
const ReviewContent = styled.p`
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 25px;
  text-align: left;
  font-size: 14px;
  margin: 10px 0 0;
  text-align: center;
`;
const Header = styled(FlexContainer)`
  padding: 20px 0;
`;
const CarouselPreview = () => {
  const dispatch = useAppDispatch();
  const {
    siteWidgetSettings,
    loadingSiteWidgetSettings,
    updateWidgetSettings: { saving },
    isWidgetSettingsUpdated,
  } = useAppSelector(settingSelector);
  const { showErrorToast, showSuccessToast } = useNotification();

  const updateSettings = () => {
    dispatch(updateWidgetsSettings())
      .then(unwrapResult)
      .then(() => {
        showSuccessToast('Settings successfully saved');
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (saving === Loading.ERROR) {
      showErrorToast('Something went wrong. Please try it again');
    }
  }, [saving]);

  if (!siteWidgetSettings) {
    return null;
  }

  const {
    brandingSettings,
    carouselSliderWidget: {
      hideSourceIcon,
      linkColor,
      showReviewDate,
      dateColor,
      textColor,
      starColor,
    },
    brandingSettings: { fontFamily, useMyWebsiteFontFamily, dateFormat },
  } = siteWidgetSettings;

  if (loadingSiteWidgetSettings === Loading.PENDING) {
    return <Preloader />;
  }

  const { carouselSliderWidget } = siteWidgetSettings;

  return (
    <>
      <Wrapper>
        <Header justifyContent="space-between">
          <BlockLabel>Carousel widget preview</BlockLabel>

          <>
            {isWidgetSettingsUpdated && (
              <FlexContainer justifyContent="flex-end" gap="10px">
                <DiscardBtn
                  onClick={() => dispatch(resetWidgetSettingsState())}
                >
                  Discard
                </DiscardBtn>
                <SaveBtn onClick={updateSettings}>
                  {saving === Loading.PENDING ? 'Saving...' : 'Save'}
                </SaveBtn>
              </FlexContainer>
            )}
          </>
        </Header>

        <PreviewSection $carouselWidget={carouselSliderWidget}>
          <WidgetCard
            $carouselWidget={carouselSliderWidget}
            $branding={brandingSettings}
          >
            <Reviewer>
              <ReviewerAvatar>
                <ReviewerPic
                  src="https://xsgames.co/randomusers/assets/avatars/female/17.jpg"
                  alt=""
                  referrerPolicy="no-referrer"
                />

                {!hideSourceIcon && (
                  <PlatformIconWrapper>
                    <YelpIcon />
                  </PlatformIconWrapper>
                )}
              </ReviewerAvatar>
              <ReviewerNameDate>
                <ReviewerName
                  color={linkColor!}
                  $fontFamily={useMyWebsiteFontFamily ? 'auto' : fontFamily}
                >
                  Joyce Filpo
                </ReviewerName>
                {showReviewDate && (
                  <ReviewerDate
                    color={dateColor!}
                    $fontFamily={useMyWebsiteFontFamily ? 'auto' : fontFamily}
                  >
                    {dayjs().format(dateFormat)}
                  </ReviewerDate>
                )}
                <ReviewRating rating={4} size={20} color={starColor!} />
              </ReviewerNameDate>
            </Reviewer>
            <ReviewContent>
              <span
                style={{
                  color: textColor!,
                  fontFamily: useMyWebsiteFontFamily ? 'auto' : fontFamily,
                }}
              >
                Have only been here twice briefly as we typically don't fly
                United internationally, but each visit has been a treat. The
                lounge itself is very spacious with multiple areas for seating,
                and it's worth going here for the a la carte dining...
              </span>
              <ReadMore color={textColor!}>Read more</ReadMore>
            </ReviewContent>
          </WidgetCard>

          <MovingParticules />
        </PreviewSection>
      </Wrapper>
    </>
  );
};

export default CarouselPreview;
