import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'rsuite';
import styled from 'styled-components';
import Preloader from '../../../shared/components/Preloader';
import ReviewsJetCrownLogo from '../../../shared/components/ReviewsJetCrownLogo';
import { useNotification } from '../../../shared/hooks/useNotification';
import { Loading } from '../../../shared/types';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  sendVerificationEmail,
  verifyAccountCreationToken,
} from '../../userData/redux/action';
import { AuthTitle } from '../components';
import ErrorPage from '../components/error-page';

/* eslint no-console: "warn" */

const StyledButton = styled(Button)`
  margin-top: 20px;
  margin-bottom: 50px;
`;
const LogoCrown = styled.img`
  height: 50px;
  /* box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1); */
  border-radius: 100%;
  /* border: 2px solid #eaeaea; */
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 40px;
  max-width: 500px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  @media (max-width: 530px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
  }
`;

const SuccessAccountCreation = () => {
  const { token } = useParams();
  const [email, setEmail] = useState('');
  const { showWarningToast, showSuccessToast } = useNotification();
  const dispatch = useAppDispatch();
  const {
    verifyAccountCreationToken: { loading },
    sendVerificationEmail: { loading: sendVerificationEmailLoading },
  } = useAppSelector((state) => state.userData);

  const handleSendVerificationEmail = () => {
    if (token) {
      dispatch(sendVerificationEmail(token))
        .then(unwrapResult)
        .then(({ ttl }) => {
          if (ttl) {
            showWarningToast(`Try it again in ${ttl}`);
          } else {
            showSuccessToast('Activation email sent – Verify your account');
          }
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    if (token) {
      dispatch(verifyAccountCreationToken(token))
        .then(unwrapResult)
        .then(({ ownerEmail }) => {
          setEmail(ownerEmail);
        })
        .catch(() => {});
    }
  }, [token]);

  if (loading === Loading.PENDING) {
    return <Preloader />;
  }

  if (!token || loading === Loading.ERROR) {
    return (
      <Wrapper>
        <ErrorPage
          showFooter={false}
          onRetryClick={() => {
            if (token) {
              dispatch(verifyAccountCreationToken(token));
            }
          }}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <a
        href="https://www.reviewsjet.com"
        style={{ marginBottom: 30, display: 'block' }}
      >
        <ReviewsJetCrownLogo />
      </a>
      <p>
        Complete your account activation by clicking the link in the email sent
        to <strong>{email}</strong>.
      </p>

      <AuthTitle fontSize="18px" marginBottom="0">
        Can't find your confirmation Email?
      </AuthTitle>
      <p>
        Please, check your <strong>Spam</strong> or <strong>Junk Email</strong>{' '}
        folders, or resend activation email.
      </p>

      <StyledButton
        appearance="ghost"
        disabled={sendVerificationEmailLoading === Loading.PENDING}
        onClick={handleSendVerificationEmail}
      >
        Resend activation email
      </StyledButton>
    </Wrapper>
  );
};

export default SuccessAccountCreation;
