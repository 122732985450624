import { Button, Input } from 'rsuite';

import { useState } from 'react';
import styled, { css } from 'styled-components';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import StyledText from '../../../../../shared/components/styled-text';
import { YelpLocationProvider } from '../../../contexts/YelpLocationContext';
import ConfirmBusiness from './ConfirmBusiness';
import ListBusinesses from './ListBusinesses';
import SearchBusiness from './SearchBusiness';
import Success from './Success';

/* eslint no-console: "warn" */

const StyledInput = styled(Input)<{ error: boolean }>`
  background: #fff;
  margin-bottom: 20px;
  border-color: #cdcdcd;
  margin-top: 5px;
  outline: none !important;
  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;
const StyledLabel = styled(StyledText)`
  font-size: 14px;
  color: #7b7b7b;
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;

const ContinueButton = styled(Button)<{ disabled?: boolean }>`
  padding-left: 30px;
  padding-right: 30px;
  margin-right: auto;
`;

const Result = styled(FlexContainer)`
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  min-height: 80px;
  margin-bottom: 20px;
  background-color: rgb(235, 238, 249);
  span.title {
    color: #000;
    font-weight: bold;
  }
  /* box-shadow: inset 0px 10px 15px -3px rgba(0, 0, 0, 0.1); */

  img {
    max-width: 100px;
    max-height: 80px;
    padding: 2px;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
  }
`;

const NoImage = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background-color: #e1e1e1;
`;

type Props = {
  onComplete: () => void;
  onConnected: () => void;
  closeModal: () => void;
};

export enum ConnectYelpStep {
  SEARCH,
  LIST,
  CONFIRM,
  SUCCESS,
}

const ConnectYelp = ({ onComplete, onConnected, closeModal }: Props) => {
  const [total, setTotal] = useState(10);
  const [step, setStep] = useState(ConnectYelpStep.SEARCH);

  return (
    <YelpLocationProvider>
      {step === ConnectYelpStep.SEARCH && (
        <SearchBusiness onSuccess={() => setStep(ConnectYelpStep.LIST)} />
      )}
      {step === ConnectYelpStep.LIST && (
        <ListBusinesses
          onGoBack={() => setStep(ConnectYelpStep.SEARCH)}
          onLocationSelected={() => {
            setStep(ConnectYelpStep.CONFIRM);
          }}
        />
      )}
      {step === ConnectYelpStep.CONFIRM && (
        <ConfirmBusiness
          onGoback={() => setStep(ConnectYelpStep.LIST)}
          onConfirmed={(total: number) => {
            onConnected();
            setStep(ConnectYelpStep.SUCCESS);
            setTotal(total);
          }}
        />
      )}
      {step === ConnectYelpStep.SUCCESS && (
        <Success
          total={total}
          onConfirmed={() => {
            closeModal();
            onComplete();
            setStep(ConnectYelpStep.SEARCH);
          }}
        />
      )}
    </YelpLocationProvider>
  );
};

export default ConnectYelp;
