import { ReactElement } from 'react';
import { FaCheck } from 'react-icons/fa';
import { GiFlame } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import OpenNewTab from '../../../shared/components/OpenNewTab';

type Props = {
  title: string;
  description: string;
  url: string;
  demoUrl: string;
  className?: string;
  icon: ReactElement;
  isHot?: boolean;
};

const SingleWidget = styled(FlexContainer)`
  position: relative;
  border: 1px solid #dadada;
  padding: 20px;
  width: 300px;
  border-radius: 10px;
  background-color: #fff;

  .widget-title {
    font-size: 15px;
    color: #000;
    font-weight: bold;
    text-transform: uppercase;
  }

  p {
    text-align: center;
    margin-bottom: 10px;
    line-height: 25px;
    color: #636363;
  }

  &:hover {
    box-shadow: 0px 10px 15px -3px #dcc5ff;
    border-color: #f5d5ff;
  }
`;

const IconWrapper = styled(FlexContainer)`
  height: 60px;
  width: 60px;
  /* border: 1px solid #edecec; */
  border-radius: 100%;
  padding: 5px;
  background-color: #f2ecfc;
  color: #531ba8;
`;

const Footer = styled(FlexContainer)`
  border-top: 1px solid #dadada;
  width: 100%;
  padding-top: 20px;
`;

const StyledGiFlame = styled(GiFlame)`
  position: absolute;
  top: 5px;
  right: 20px;
`;

export default function WidgetCard({
  demoUrl,
  description,
  url,
  title,
  icon,
  isHot,
}: Props) {
  const navigate = useNavigate();

  return (
    <SingleWidget stack gap="10px" justifyContent="space-between">
      {isHot && (
        <StyledGiFlame color="#ff7600" size={25} title="Trending widget" />
      )}

      <FlexContainer stack gap="10px">
        <IconWrapper>{icon}</IconWrapper>
        <h3 className="widget-title">{title}</h3>
        <p>{description}</p>
      </FlexContainer>

      <Footer gap="5px">
        <Button appearance="ghost" color="violet" onClick={() => navigate(url)}>
          <FlexContainer gap="5px">
            <FaCheck />
            Select widget
          </FlexContainer>
        </Button>

        <Button
          appearance="link"
          onClick={() => window.open(demoUrl, '_blank')}
        >
          <FlexContainer gap="5px">
            View demo
            <OpenNewTab />
          </FlexContainer>
        </Button>
      </Footer>
    </SingleWidget>
  );
}
