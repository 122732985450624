import { unwrapResult } from '@reduxjs/toolkit';
import { InputPicker, Tag } from 'rsuite';
import { selectSeat } from '../../features/userData/redux/action';
import { accountSelector } from '../../features/userData/redux/userData.slice';
import InputPickerLabel from '../../shared/components/InputPickerLabel';
import FlexContainer from '../../shared/components/layouts/flex-container';
import { useNotification } from '../../shared/hooks/useNotification';
import { Loading } from '../../shared/types';
import capitalize from '../../shared/utils/capitalize';
import setSession from '../../shared/utils/setSession';
import { useAppDispatch, useAppSelector } from '../../store';

/* eslint no-console: "warn" */

const SeatSelector = () => {
  const dispatch = useAppDispatch();
  const { account, loading } = useAppSelector(accountSelector);
  const { selectedSite, seats = [] } = account ?? {};
  const { showErrorToast } = useNotification();

  if (loading !== Loading.SUCCESS) {
    return null;
  }

  return (
    <div style={{ maxWidth: 250, paddingLeft: 10 }}>
      {seats.length > 1 ? (
        <InputPicker
          size="sm"
          cleanable={false}
          data={seats
            .filter(
              (seat) =>
                ![
                  '65a58aaa8e7e78680643d312',
                  '6682d52bcd5af1b56c6ee4d9',
                ].includes(seat._id)
            )
            .map((seat) => ({
              label: `${seat.siteDisplayName ?? seat.url} (${capitalize(
                seat.platform
              )})`,
              value: seat._id,
            }))}
          renderValue={() => {
            return (
              <InputPickerLabel
                label=""
                value={selectedSite?.siteDisplayName ?? selectedSite?.url ?? ''}
              />
            );
          }}
          value={selectedSite?._id ?? ''}
          onChange={(value) => {
            dispatch(selectSeat(value))
              .then(unwrapResult)
              .then(setSession)
              .then(() => (window.location.href = '/manage-reviews'))
              .catch((e) => {
                showErrorToast('Something went wrong. Please try it again.');
              });
          }}
          style={{ width: '100%' }}
        />
      ) : (
        <FlexContainer justifyContent="flex-start" gap="5px">
          <b>Workspace: </b>
          <Tag color="blue">
            {selectedSite?.siteDisplayName ?? selectedSite?.url}
          </Tag>
        </FlexContainer>
      )}
    </div>
  );
};

export default SeatSelector;
