import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonToolbar, Form, Schema } from 'rsuite';
import styled from 'styled-components';
import ReviewsJetCrownLogo from '../../../shared/components/ReviewsJetCrownLogo';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../shared/hooks/useNotification';
import { Loading } from '../../../shared/types';
import { useAppDispatch, useAppSelector } from '../../../store';
import { forgotPassword } from '../../userData/redux/action';
import {
  AuthForm,
  AuthFormGroup,
  AuthLabel,
  AuthTextField,
  AuthTitle,
} from '../components';

const StyledFlexContainer = styled(FlexContainer)`
  border-top: 1px solid #eeeeee;
  padding: 10px 0;
  /* min-width: 350px; */
  margin-top: 30px;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 40px;
  max-width: 430px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  @media (max-width: 530px) {
    max-width: 100%;
    height: 100%;
  }
`;
const LogoCrown = styled.img`
  height: 50px;
  /* box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1); */
  border-radius: 100%;
  /* border: 2px solid #eaeaea; */
`;

const { StringType } = Schema.Types;

/* eslint no-console: "warn" */

const model = Schema.Model({
  email: StringType()
    .isEmail('Please enter a valid email address.')
    .isRequired('This field is required.'),
});

const ForgotPasswordPage = () => {
  const dispatch = useAppDispatch();
  const [submitted, setSubmitted] = useState(false);
  const { showErrorToast } = useNotification();
  const formRef = useRef<{ check: () => void }>(null);
  const {
    forgotPassword: { loading },
  } = useAppSelector((state) => state.userData);

  const [formValue, setFormValue] = useState({
    email: '',
  });

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      if (!formRef.current?.check()) {
        return;
      }

      dispatch(forgotPassword(formValue.email))
        .then(unwrapResult)
        .then(() => setSubmitted(true))
        .catch(() => {
          showErrorToast('An error occurred. Please try it again.');
        });
    },
    [formValue]
  );

  return (
    <Wrapper>
      <ReviewsJetCrownLogo />
      {submitted ? (
        <>
          <AuthTitle>Password reset submitted!</AuthTitle>
          <p>
            We've received your request. Check your email for further
            instructions on resetting your password.
          </p>
          <StyledFlexContainer gap="10px">
            <Link to="/login">Go back to sign-in</Link>
          </StyledFlexContainer>
        </>
      ) : (
        <>
          <AuthTitle>Forgot password</AuthTitle>

          <AuthForm
            fluid
            ref={formRef}
            onChange={setFormValue}
            formValue={formValue}
            model={model}
          >
            <Form.Group>
              <p>
                Please enter the email address you used when creating your
                account.
              </p>
            </Form.Group>
            <AuthFormGroup controlId="email">
              <AuthLabel>Account email address</AuthLabel>
              <AuthTextField
                name="email"
                type="email"
                placeholder="e.g. jane@doe.com"
              />
            </AuthFormGroup>

            <Form.Group>
              <ButtonToolbar>
                <Button
                  block
                  size="lg"
                  color="violet"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loading === Loading.PENDING}
                  appearance={
                    loading === Loading.PENDING ? 'default' : 'primary'
                  }
                >
                  {loading === Loading.PENDING
                    ? 'Please wait...'
                    : 'Send reset email'}
                </Button>
              </ButtonToolbar>
            </Form.Group>

            <StyledFlexContainer gap="10px">
              <Link to="/login">Go back to sign-in</Link>
            </StyledFlexContainer>
          </AuthForm>
        </>
      )}
    </Wrapper>
  );
};

export default ForgotPasswordPage;
