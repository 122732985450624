import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '.';
import { axiosPrivate } from '../services/axiosPrivate';
import { Loading } from '../shared/types';

export const verifyInstance = createAsyncThunk<
  any,
  any,
  { rejectValue: string; state: RootState }
>(
  'wix/verifyInstance',
  async ({ instance }: { instance: string }, { rejectWithValue }) => {
    try {
      const url = `wix/verifyInstance?instance=${instance}`;
      const response = await axiosPrivate.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        userData: { loading },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const confirmInstallation = createAsyncThunk<
  any,
  any,
  { rejectValue: string }
>(
  'wix/confirmInstallation',
  async ({ instance }: { instance: string }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(`wix/confirm-installation`, {
        instance,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);
