const fonts = {
  primary: "'Roboto', sans-serif",
};

const fontSizes = {
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '1.875rem',
  '4xl': '2.25rem',
  '5xl': '3rem',
  '6xl': '4rem',
};

const lineHeights = {
  xs: '1rem',
  sm: '1.25rem',
  md: '1.5rem',
  lg: '1.75rem',
  xl: '1.75rem',
  '2xl': '2rem',
  '3xl': '2.25rem',
  '4xl': '2.5rem',
  '5xl': '1',
  '6xl': '1',
};

const letterSpacings = {
  tighter: '-0.05em',
  tight: '-0.025em',
  normal: '0em',
  wide: '0.025em',
  wider: '0.05em',
  widest: '0.1em',
};

const fontWeights = {
  thin: 100,
  extralight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};

export type LetterSpacingType = {
  [key in keyof typeof letterSpacings]: string;
};
export type FontsType = { [key in keyof typeof fonts]: string };
export type FontSizeType = { [key in keyof typeof fontSizes]: string };
export type FontWeightType = { [key in keyof typeof fontWeights]: number };
export type LineHeightType = { [key in keyof typeof lineHeights]: string };

export type TypographyType = {
  fontSizes: FontSizeType;
  lineHeights: LineHeightType;
  letterSpacings: LetterSpacingType;
  fontWeights: FontWeightType;
  fonts: FontsType;
};

export const typography: TypographyType = {
  fontSizes,
  lineHeights,
  letterSpacings,
  fontWeights,
  fonts,
};

export default typography;
