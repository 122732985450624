import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { Loading } from '../../../shared/types';
import { listWebsites } from './actions';

type WebflowWebsite = {
  id: string;
  workspaceId: string;
  customDomains: { id: string; url: string }[];
  displayName: string;
};

type WebflowState = {
  sites: {
    loading: Loading;
    items: WebflowWebsite[];
  };
  selectedSite: string | null;
};

const initialState: WebflowState = {
  sites: {
    loading: Loading.INITIAL,
    items: [],
  },
  selectedSite: null,
};

const site = createSlice({
  name: 'webflow',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listWebsites.pending, (state) => {
      state.sites.loading = Loading.PENDING;
    });
    builder.addCase(listWebsites.rejected, (state) => {
      state.sites.loading = Loading.ERROR;
    });
    builder.addCase(listWebsites.fulfilled, (state, { payload }) => {
      state.sites.loading = Loading.SUCCESS;
      state.sites.items = payload;
    });
  },
});

export const webflowSelector = (state: RootState) => state.webflow;

// export const {} = site.actions;

export default site.reducer;
