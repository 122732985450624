import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { Loading, PlanCycle, SubscriptionPlan } from '../../../shared/types';
import siteConfigs from '../../../shared/utils/siteConfigs';
import { getLimit, getTotalCredit } from '../../../shared/utils/usage-limits';
import { RootState } from '../../../store';
import { confirmInstallation, verifyInstance } from '../../../store/actions';
import { ReviewsJetPlatform } from '../../product/types';
import {
  addSeat,
  createAccount,
  forgotPassword,
  getAccount,
  getBusinessInformation,
  getUpgradeUrl,
  login,
  redeemCode,
  selectSeat,
  sendVerificationEmail,
  updateBusinessInformation,
  updatePassword,
  updateSeat,
  validateRedemptionCode,
  verifyAccount,
  verifyAccountCreationToken,
  verifyPasswordResetToken,
} from './action';

/* eslint no-console: "warn" */

export type Seat = {
  _id: string;
  instanceId: string;
  locale: string;
  siteDisplayName: string;
  url: string;
  siteId: string;
  plan: SubscriptionPlan;
  planCycle: PlanCycle;
  platform: ReviewsJetPlatform;
  wixRefreshToken: string;
  createdAt: string;
  updatedAt: string;
  stripe: StripeData | null;
  unInstalled: boolean;
};

type StripeData = {
  customerId: string;
  subscriptionId: string;
};

type Account = {
  _id: string;
  locale: string;
  isVerified: boolean;
  ownerEmail: string;
  uninstalled: boolean;
  jwtAccessToken: string;
  seats: Seat[];
  source: string;
  selectedSite: Seat | null;
  stripe: StripeData | null;
};

export enum CelebrationType {
  ONLY_CONFETTI,
  CONFETTI_WITH_A_WELCOME_MESSAGE,
}

type UserDataState = {
  celebrate: CelebrationType | null;
  account: Account | null;
  loading: Loading;
  error: string | null;
  upgradeUrl: string | null;
  showUpgradeModal: boolean;
  openFeedback: boolean;
  confirmInstallation: {
    loading: Loading;
  };
  createAccount: {
    loading: Loading;
  };
  verifyAccountCreationToken: {
    loading: Loading;
  };
  sendVerificationEmail: {
    loading: Loading;
  };
  verifyAccount: {
    loading: Loading;
  };
  login: {
    loading: Loading;
  };
  validate: {
    loading: Loading;
  };
  addSeat: {
    loading: Loading;
  };
  selectSeat: {
    selectedSeatId: string | null;
    loading: Loading;
  };
  forgotPassword: {
    loading: Loading;
  };
  updatePassword: {
    loading: Loading;
  };
  verifyPasswordResetToken: {
    loading: Loading;
  };
  updateSeat: {
    loading: Loading;
  };
  redeemCode: {
    loading: Loading;
  };
  getGoogleRichSnippet: {
    loading: Loading;
    status: boolean;
    data: null | {
      siteId: string;
      name: string;
      streetAddress: string;
      addressLocality: string;
      addressRegion: string;
      postalCode: string;
      addressCountry: string;
      telephone: string;
      url: string;
    };
  };
  updateBusinessInformation: {
    loading: Loading;
  };
  verifyInstance: {
    loading: Loading;
  };
};

const initialState: UserDataState = {
  celebrate: null,
  account: null,
  loading: Loading.INITIAL,
  error: null,
  upgradeUrl: null,
  showUpgradeModal: false,
  openFeedback: false,
  confirmInstallation: {
    loading: Loading.INITIAL,
  },
  createAccount: {
    loading: Loading.INITIAL,
  },
  verifyAccountCreationToken: {
    loading: Loading.INITIAL,
  },
  sendVerificationEmail: {
    loading: Loading.INITIAL,
  },
  verifyAccount: {
    loading: Loading.INITIAL,
  },
  login: {
    loading: Loading.INITIAL,
  },
  validate: {
    loading: Loading.INITIAL,
  },
  addSeat: {
    loading: Loading.INITIAL,
  },
  selectSeat: {
    selectedSeatId: null,
    loading: Loading.INITIAL,
  },
  forgotPassword: {
    loading: Loading.INITIAL,
  },
  updatePassword: {
    loading: Loading.INITIAL,
  },
  verifyPasswordResetToken: {
    loading: Loading.INITIAL,
  },
  updateSeat: {
    loading: Loading.INITIAL,
  },
  redeemCode: {
    loading: Loading.INITIAL,
  },
  getGoogleRichSnippet: {
    loading: Loading.INITIAL,
    status: false,
    data: null,
  },
  updateBusinessInformation: {
    loading: Loading.INITIAL,
  },
  verifyInstance: {
    loading: Loading.INITIAL,
  },
};

const userData = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    toggleUpgradeModal: (state) => {
      state.showUpgradeModal = !state.showUpgradeModal;
    },
    toggleFeedbackDrawer: (state) => {
      state.openFeedback = !state.openFeedback;
    },
    resetRedemptionCodeValidation: (state) => {
      state.validate.loading = Loading.INITIAL;
    },
    setCelebration: (state, { payload }) => {
      state.celebrate = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(verifyInstance.pending, (state) => {
      state.verifyInstance.loading = Loading.PENDING;
      state.loading = Loading.PENDING;
    });
    builder.addCase(verifyInstance.fulfilled, (state, { payload }) => {
      delete payload.refreshToken;
      state.account = payload;
      state.verifyInstance.loading = Loading.SUCCESS;
      state.loading = Loading.SUCCESS;
    });
    builder.addCase(verifyInstance.rejected, (state) => {
      state.verifyInstance.loading = Loading.ERROR;
      state.loading = Loading.ERROR;
    });
    builder.addCase(
      getAccount.pending,
      (
        state,
        {
          meta: {
            arg: { showPreloader },
          },
        }
      ) => {
        if (!!showPreloader) {
          state.loading = Loading.PENDING;
        }
      }
    );
    builder.addCase(getAccount.fulfilled, (state, { payload }) => {
      state.loading = Loading.SUCCESS;
      state.account = payload;
    });
    builder.addCase(getAccount.rejected, (state) => {
      state.loading = Loading.ERROR;
    });
    builder.addCase(getUpgradeUrl.fulfilled, (state, { payload }) => {
      state.upgradeUrl = payload;
    });
    builder.addCase(confirmInstallation.pending, (state) => {
      state.confirmInstallation.loading = Loading.PENDING;
    });
    builder.addCase(confirmInstallation.fulfilled, (state, { payload }) => {
      delete payload.refreshToken;
      state.confirmInstallation.loading = Loading.SUCCESS;
    });
    builder.addCase(confirmInstallation.rejected, (state) => {
      state.confirmInstallation.loading = Loading.ERROR;
    });

    builder.addCase(createAccount.pending, (state) => {
      state.createAccount.loading = Loading.PENDING;
    });
    builder.addCase(createAccount.rejected, (state) => {
      state.createAccount.loading = Loading.ERROR;
    });
    builder.addCase(createAccount.fulfilled, (state) => {
      state.createAccount.loading = Loading.SUCCESS;
    });
    builder.addCase(verifyAccountCreationToken.pending, (state) => {
      state.verifyAccountCreationToken.loading = Loading.PENDING;
    });
    builder.addCase(verifyAccountCreationToken.rejected, (state) => {
      state.verifyAccountCreationToken.loading = Loading.ERROR;
    });
    builder.addCase(verifyAccountCreationToken.fulfilled, (state) => {
      state.verifyAccountCreationToken.loading = Loading.SUCCESS;
    });

    builder.addCase(sendVerificationEmail.pending, (state) => {
      state.sendVerificationEmail.loading = Loading.PENDING;
    });
    builder.addCase(sendVerificationEmail.rejected, (state) => {
      state.sendVerificationEmail.loading = Loading.ERROR;
    });
    builder.addCase(sendVerificationEmail.fulfilled, (state) => {
      state.sendVerificationEmail.loading = Loading.SUCCESS;
    });
    builder.addCase(verifyAccount.pending, (state) => {
      state.verifyAccount.loading = Loading.PENDING;
    });
    builder.addCase(verifyAccount.rejected, (state) => {
      state.verifyAccount.loading = Loading.ERROR;
    });
    builder.addCase(verifyAccount.fulfilled, (state) => {
      state.verifyAccount.loading = Loading.SUCCESS;
    });
    builder.addCase(login.pending, (state) => {
      state.login.loading = Loading.PENDING;
    });
    builder.addCase(login.rejected, (state) => {
      state.login.loading = Loading.ERROR;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.login.loading = Loading.SUCCESS;

      if (payload.showWelcomeMessage) {
        state.celebrate = CelebrationType.CONFETTI_WITH_A_WELCOME_MESSAGE;
      }
    });
    builder.addCase(validateRedemptionCode.pending, (state) => {
      state.validate.loading = Loading.PENDING;
    });
    builder.addCase(validateRedemptionCode.rejected, (state) => {
      state.validate.loading = Loading.ERROR;
    });
    builder.addCase(validateRedemptionCode.fulfilled, (state) => {
      state.validate.loading = Loading.SUCCESS;
    });
    builder.addCase(selectSeat.pending, (state, { meta }) => {
      state.selectSeat.selectedSeatId = meta.arg;
      state.selectSeat.loading = Loading.PENDING;
    });
    builder.addCase(selectSeat.rejected, (state) => {
      state.selectSeat.loading = Loading.ERROR;
    });
    builder.addCase(selectSeat.fulfilled, (state, { payload }) => {
      state.selectSeat.loading = Loading.SUCCESS;
      // state.account = payload;
    });
    builder.addCase(addSeat.pending, (state) => {
      state.addSeat.loading = Loading.PENDING;
    });
    builder.addCase(addSeat.rejected, (state) => {
      state.addSeat.loading = Loading.ERROR;
    });
    builder.addCase(addSeat.fulfilled, (state, { payload }) => {
      state.addSeat.loading = Loading.SUCCESS;
      state.account = payload;
    });
    builder.addCase(forgotPassword.pending, (state) => {
      state.forgotPassword.loading = Loading.PENDING;
    });
    builder.addCase(forgotPassword.rejected, (state) => {
      state.forgotPassword.loading = Loading.ERROR;
    });
    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.forgotPassword.loading = Loading.SUCCESS;
    });
    builder.addCase(updatePassword.pending, (state) => {
      state.updatePassword.loading = Loading.PENDING;
    });
    builder.addCase(updatePassword.rejected, (state) => {
      state.updatePassword.loading = Loading.ERROR;
    });
    builder.addCase(updatePassword.fulfilled, (state) => {
      state.updatePassword.loading = Loading.SUCCESS;
    });
    builder.addCase(verifyPasswordResetToken.pending, (state) => {
      state.verifyPasswordResetToken.loading = Loading.PENDING;
    });
    builder.addCase(verifyPasswordResetToken.rejected, (state) => {
      state.verifyPasswordResetToken.loading = Loading.ERROR;
    });
    builder.addCase(verifyPasswordResetToken.fulfilled, (state) => {
      state.verifyPasswordResetToken.loading = Loading.SUCCESS;
    });

    builder.addCase(updateSeat.pending, (state) => {
      state.updateSeat.loading = Loading.PENDING;
    });
    builder.addCase(updateSeat.rejected, (state) => {
      state.updateSeat.loading = Loading.ERROR;
    });
    builder.addCase(updateSeat.fulfilled, (state, { payload }) => {
      state.updateSeat.loading = Loading.SUCCESS;
    });

    builder.addCase(redeemCode.pending, (state) => {
      state.redeemCode.loading = Loading.PENDING;
    });
    builder.addCase(redeemCode.rejected, (state) => {
      state.redeemCode.loading = Loading.ERROR;
    });
    builder.addCase(redeemCode.fulfilled, (state, { payload }) => {
      state.redeemCode.loading = Loading.SUCCESS;

      if (payload.success) {
        state.celebrate = CelebrationType.ONLY_CONFETTI;
      }
    });

    builder.addCase(getBusinessInformation.pending, (state) => {
      state.getGoogleRichSnippet.loading = Loading.PENDING;
    });
    builder.addCase(getBusinessInformation.rejected, (state) => {
      state.getGoogleRichSnippet.loading = Loading.ERROR;
    });
    builder.addCase(getBusinessInformation.fulfilled, (state, { payload }) => {
      state.getGoogleRichSnippet.loading = Loading.SUCCESS;
      state.getGoogleRichSnippet.data = payload.businessInformation;
    });

    builder.addCase(updateBusinessInformation.pending, (state) => {
      state.updateBusinessInformation.loading = Loading.PENDING;
    });
    builder.addCase(updateBusinessInformation.rejected, (state) => {
      state.updateBusinessInformation.loading = Loading.ERROR;
    });
    builder.addCase(
      updateBusinessInformation.fulfilled,
      (state, { payload }) => {
        state.updateBusinessInformation.loading = Loading.SUCCESS;
        state.getGoogleRichSnippet.data = payload.businessInformation;
      }
    );
  },
});

export const accountSelector = (state: RootState) => state.userData;
export const userAccountConfigSelector = createSelector(
  accountSelector,
  (userData) => {
    const account = userData.account;

    const { selectedSite } = account ?? {};
    const { platform } = selectedSite ?? {};

    if (platform) {
      return siteConfigs[platform];
    }
  }
);

export const userCreditSelector = createSelector(
  accountSelector,
  (userData) => {
    const account = userData.account;

    const { selectedSite } = account ?? {};
    const { plan } = selectedSite ?? {};

    const seatsCredit = getLimit(plan).seat;
    const reviewsCredit = getLimit(plan).reviews;
    const reviewRequestsCredit = getLimit(plan).reviewsRequests;
    const integrationsCredit = getLimit(plan).integrations;

    const totalSeatCredit = getTotalCredit(seatsCredit);
    const totalReviewsCredit = getTotalCredit(reviewsCredit);
    const totalReviewRequestsCredit = getTotalCredit(reviewRequestsCredit);
    const totalIntegrationsCredit = getTotalCredit(integrationsCredit);

    return {
      totalSeatCredit,
      totalReviewsCredit,
      totalReviewRequestsCredit,
      totalIntegrationsCredit,
    };
  }
);

export const seatsSelector = createSelector(accountSelector, (userData) => {
  return userData.account?.seats ?? [];
});

export const {
  setCelebration,
  toggleUpgradeModal,
  toggleFeedbackDrawer,
  resetRedemptionCodeValidation,
} = userData.actions;

export default userData.reducer;
