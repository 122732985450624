import { Breadcrumb } from 'rsuite';
import styled from 'styled-components';
import ContactSupport from '../../../../shared/components/ContactSupport';
import NavLink from '../../../../shared/components/NavLink';
import CarouselWidgetType from './carousel-widget-type';

const Wrapper = styled.div`
  padding: 20px 30px;
  max-width: 950px;
  margin: auto;
`;

const Title = styled.h1`
  font-size: 25px;
  margin-top: 0;
  color: #000;
`;

const PageTitle = styled(Title)`
  color: #2c2c2c;
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 10px;
`;
const Slider = () => {
  return (
    <>
      <ContactSupport />
      <Wrapper>
        <PageTitle>Carousel Slider</PageTitle>
        <Breadcrumb separator=">">
          <Breadcrumb.Item href="/select-widgets" as={NavLink}>
            Widgets
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Carousel slider</Breadcrumb.Item>
        </Breadcrumb>
        <CarouselWidgetType widgetType="slider" />
      </Wrapper>
    </>
  );
};

export default Slider;
