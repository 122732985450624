import { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { GoDash } from 'react-icons/go';
import styled from 'styled-components';
import { PLAN_PRICES } from '../../../../features/review/utils/constants';
import { accountSelector } from '../../../../features/userData/redux/userData.slice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setBillingData } from '../../../../store/app.slice';
import { PlanCycle, SubscriptionPlan } from '../../../types';
import USAGE from '../../../utils/usage-limits';
import FlexContainer from '../../layouts/flex-container';
import BillingCycleSwitcher from './BillingCycleSwitcher';
import PlanCard from './PlanCard';

const Wrapper = styled.div`
  max-height: 535px;
`;

const Table = styled.table`
  margin-top: 20px;
  width: 100%;
  .section {
    color: #000;
    font-size: 15px;
    font-weight: bold;
    background-color: rgb(250, 250, 252);
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    padding: 10px 0 10px 10px;
  }

  th {
    font-size: 15px;
  }

  td {
    padding: 10px 0 10px 10px;
    border-bottom: 1px solid #f1f1f1;
  }

  th {
    padding: 0px 0 30px 15px;
    text-align: left;
  }
  label {
    font-size: 14px;
    font-weight: 500;
  }
  .price {
    color: #000;
    font-weight: bold;
    font-size: 17px;
  }
  .per-month {
    font-size: 14px;
    color: #000;
    font-weight: bold;
  }
  .billed-annually {
    font-size: 12px;
    margin-top: -5px;
    font-weight: 500;
  }
  .discount {
    font-weight: 600;
    background-color: #f0faf8;
    color: #226453;
    border: 1px solid #d4e5e1;
    border-radius: 5px;
  }
  .rs-radio-tile-content {
    padding-top: 5px;
  }
  .rs-radio-tile-label {
    font-size: 15px;
  }
  .original-price {
    font-size: 13px;
    text-decoration: line-through;
  }
`;

const PriceCard = styled.div`
  min-width: 200px;
  display: flex;
  padding: 10px;
  position: relative;
  border-radius: 6px;
  border-width: 1px;
  min-height: 90px;
  border: 1px solid #f4f2f2;
  align-items: flex-start !important;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  &:hover {
    border-width: 1px !important;
  }
  .price-name {
    font-size: 15px;
    margin-bottom: 0;
  }

  @media (max-width: 690px) {
    width: 100%;
  }
`;

const StyledFlexContainer = styled(FlexContainer)`
  @media only screen and (max-width: 910px) {
    display: block;

    .cycle-switcher {
      justify-content: center !important;
      margin-bottom: 15px;
    }
    .price-cards {
      justify-content: center !important;
    }
  }

  @media only screen and (max-width: 690px) {
    .price-cards {
      flex-direction: column;
    }
  }
`;

const Upgrade = () => {
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { plan } = selectedSite ?? {};
  const [cycle, setCycle] = useState<PlanCycle>(PlanCycle.YEARLY);
  const [selectedPlan, setSelectedPlan] = useState<
    'basic' | 'professional' | 'advanced'
  >('professional');

  useEffect(() => {
    dispatch(
      setBillingData({
        upgradePlanId: selectedPlan,
        billingCycle: selectedPlan === 'basic' ? 'NO_CYCLE' : cycle,
      })
    );
  }, [selectedPlan, cycle]);

  return (
    <Wrapper>
      <Table>
        <thead>
          <tr>
            <th colSpan={4}>
              <StyledFlexContainer
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <BillingCycleSwitcher
                  cycle={cycle}
                  switchCycle={(cycle) => setCycle(cycle)}
                />

                <FlexContainer
                  justifyContent="flex-end"
                  alignItems="stretch"
                  gap="10px"
                  className="price-cards"
                >
                  <PlanCard
                    key="Basic"
                    unit="forever"
                    isCurrentPlan={plan === SubscriptionPlan.FREE}
                    planName="Basic"
                    price={0}
                    isSelectedPlan={selectedPlan === 'basic'}
                    selectPlan={() => setSelectedPlan('basic')}
                  />

                  <PlanCard
                    key="Pro"
                    cycle={cycle}
                    unit="month"
                    isCurrentPlan={plan === SubscriptionPlan.PRO}
                    planName="Pro"
                    monthlyPrice={PLAN_PRICES.PRO.monthly}
                    price={
                      cycle === PlanCycle.MONTHLY
                        ? PLAN_PRICES.PRO.monthly
                        : PLAN_PRICES.PRO.anually
                    }
                    isSelectedPlan={selectedPlan === 'professional'}
                    selectPlan={() => setSelectedPlan('professional')}
                  />

                  <PlanCard
                    key="Advanced"
                    cycle={cycle}
                    unit="month"
                    isCurrentPlan={plan === SubscriptionPlan.ADVANCED}
                    planName="Advanced"
                    monthlyPrice={PLAN_PRICES.ADVANCED.monthly}
                    price={
                      cycle === PlanCycle.MONTHLY
                        ? PLAN_PRICES.ADVANCED.monthly
                        : PLAN_PRICES.ADVANCED.anually
                    }
                    isSelectedPlan={selectedPlan === 'advanced'}
                    selectPlan={() => setSelectedPlan('advanced')}
                  />
                </FlexContainer>
              </StyledFlexContainer>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={4} className="section">
              Usage limit
            </td>
          </tr>
          <tr>
            <td>Published reviews</td>
            <td>
              {USAGE.free.reviews === -1 ? 'Unlimited' : USAGE.free.reviews}
            </td>
            <td>
              {USAGE.professional.reviews === -1
                ? 'Unlimited'
                : USAGE.professional.reviews}
            </td>
            <td>
              {USAGE.advanced.reviews === -1
                ? 'Unlimited'
                : USAGE.advanced.reviews}
            </td>
          </tr>

          <tr>
            <td>Review requests</td>
            <td>
              {USAGE.free.reviewsRequests === -1
                ? 'Unlimited'
                : USAGE.free.reviewsRequests}
            </td>
            <td>
              {USAGE.professional.reviewsRequests === -1
                ? 'Unlimited'
                : USAGE.professional.reviewsRequests}
            </td>
            <td>
              {USAGE.advanced.reviewsRequests === -1
                ? 'Unlimited'
                : USAGE.advanced.reviewsRequests}
            </td>
          </tr>
          <tr>
            <td>Third-party imports</td>
            <td>
              {USAGE.free.integrations === -1
                ? 'Unlimited'
                : USAGE.free.integrations}
            </td>
            <td>
              {USAGE.professional.integrations === -1
                ? 'Unlimited'
                : USAGE.professional.integrations}
            </td>
            <td>
              {USAGE.advanced.integrations === -1
                ? 'Unlimited'
                : USAGE.advanced.integrations}
            </td>
          </tr>
          <tr>
            <td>Number of widgets</td>
            <td>
              {USAGE.free.widgets === -1 ? 'Unlimited' : USAGE.free.widgets}
            </td>
            <td>
              {USAGE.professional.widgets === -1
                ? 'Unlimited'
                : USAGE.professional.widgets}
            </td>
            <td>
              {USAGE.advanced.widgets === -1
                ? 'Unlimited'
                : USAGE.advanced.widgets}
            </td>
          </tr>
          <tr>
            <td colSpan={4} className="section">
              Features
            </td>
          </tr>
          <tr>
            <td>Google Rich Snippets</td>
            <td>
              <GoDash size={15} />
            </td>
            <td>
              <GoDash size={15} />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Full widget customization</td>
            <td>
              <GoDash size={15} />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Support for mobile devices</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Reply to reviews</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Auto-publish reviews</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Widget content translation</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Product reviews anywhere</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Bulk reviews import</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Review requests</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Third-party imports</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>

          <tr>
            <td colSpan={4} className="section">
              Widgets
            </td>
          </tr>
          <tr>
            <td>Remove ReviewsJet branding</td>
            <td>
              <GoDash size={15} />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Carousel Slider</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Carousel Marquee</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Masonry widget</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Grid widget</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>List widget</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Modal widget</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
          <tr>
            <td>Product widget</td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
            <td>
              <FaCheck color="#673ab7" />
            </td>
          </tr>
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default Upgrade;
