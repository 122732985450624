import { useTranslation } from 'react-i18next';
import { Button } from 'rsuite';
import styled from 'styled-components';

const Container = styled.div`
  padding-top: 0;
`;
const MainWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  p {
    line-height: 20px;
    margin-bottom: 30px;
  }
`;
const Title = styled.h3`
  color: #000;
  font-weight: bold;
  font-size: 25px;
  margin: 0 auto 15px;
  text-align: center;
`;
const ConfirmButton = styled(Button)<{ disabled?: boolean }>`
  padding-left: 20px;
  padding-right: 20px;
  max-width: 200px;
  margin: auto;
`;

interface Props {
  onConfirmed: () => void;
}

const Success = ({ onConfirmed }: Props) => {
  const { t } = useTranslation('common');
  return (
    <>
      <Container>
        <MainWrapper>
          <Title>{t('shared.success')}!</Title>
          <p>{t('reviews.import-source.apple.success-add-message')}</p>
        </MainWrapper>

        <ConfirmButton block appearance="default" onClick={onConfirmed}>
          {t('shared.close')}
        </ConfirmButton>
      </Container>
    </>
  );
};

export default Success;
