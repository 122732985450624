import styled from 'styled-components';
import { FlexContainerJustifyContent } from '../../../shared/components/layouts/flex-container';

const FormRow = styled.div<{
  width?: string;
  marginTop?: string;
  marginBottom?: string;
  justifyContent?: FlexContainerJustifyContent;
  gap?: string;
  stack?: boolean;
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
}>`
  flex-direction: ${({ stack }) => (stack ? 'column' : 'row')};
  width: ${({ width }) => width || '100%'};
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'space-between'};
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '20px'};
  align-items: center;
  gap: ${({ gap }) => gap ?? '0'};
  flex-wrap: wrap;
  align-items: ${({ alignItems }) => alignItems};
`;

export default FormRow;
