import { useEffect } from 'react';
import './style.css';

const MovingParticules = () => {
  useEffect(() => {
    const container = document.getElementById('particle-container');

    for (let i = 0; i < 50; i++) {
      const particle = document.createElement('div');
      particle.className = 'particle';
      const top = Math.random() * 100;
      const left = Math.random() * 100;
      const delay = Math.random() * 20;
      const duration = 20 + Math.random() * 10;

      particle.style.top = `${top}%`;
      particle.style.left = `${left}%`;
      particle.style.animationDelay = `${delay}s`;
      particle.style.animationDuration = `${duration}s`;

      container?.appendChild(particle);
    }
  }, []);

  return <div className="container" id="particle-container"></div>;
};

export default MovingParticules;
