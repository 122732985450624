import { unwrapResult } from '@reduxjs/toolkit';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, ButtonToolbar, Form, Schema } from 'rsuite';
import styled from 'styled-components';
import ReviewsJetCrownLogo from '../../../shared/components/ReviewsJetCrownLogo';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../shared/hooks/useNotification';
import { Loading } from '../../../shared/types';
import setSession from '../../../shared/utils/setSession';
import { useAppDispatch, useAppSelector } from '../../../store';
import { login } from '../../userData/redux/action';
import {
  AuthForm,
  AuthFormGroup,
  AuthIconWrapper,
  AuthLabel,
  AuthTextField,
  AuthTitle,
} from '../components';

const StyledFlexContainer = styled(FlexContainer)`
  border-top: 1px solid #eeeeee;
  padding: 10px 0;
  /* min-width: 350px; */
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 40px;
  max-width: 430px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  @media (max-width: 530px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
  }
`;
const LogoCrown = styled.img`
  height: 50px;
  /* box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1); */
  border-radius: 100%;
  /* border: 2px solid #eaeaea; */
`;

const LoginButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;
`;

const LoginButton = styled.a`
  display: flex;
  gap: 5px;
  border: 1px solid #e5e5ea;
  border-radius: 6px;
  padding: 10px 15px;
  align-items: center;
  color: #000;
  font-size: 15px;
  justify-content: center;

  &:hover {
    background-color: #000;
    color: #fff;
  }
`;

const { StringType } = Schema.Types;

const model = Schema.Model({
  email: StringType()
    .isEmail('Please enter a valid email address.')
    .isRequired('This field is required.'),
  password: StringType().isRequired('This field is required.'),
});

const LoginPage = () => {
  const navigate = useNavigate();
  const { showErrorToast } = useNotification();
  const dispatch = useAppDispatch();
  const captchaRef = useRef<any>(null);
  const formRef = useRef<{ check: () => void }>(null);
  const [visible, setVisible] = useState(false);
  const {
    login: { loading },
  } = useAppSelector((state) => state.userData);

  const [formValue, setFormValue] = useState({
    email: '',
    password: '',
  });

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!formRef.current?.check()) {
      return;
    }

    dispatch(login(formValue))
      .then(unwrapResult)
      .then((res) => {
        if (res.confirmationCode) {
          navigate(`/account-creation-success/${res.confirmationCode}`);
        } else {
          const { jwtAccessToken, jwtRefreshToken } = res;

          setSession({ jwtAccessToken, jwtRefreshToken });

          navigate('/manage-reviews');
        }
      })
      .catch(() => {
        showErrorToast('Invalid email and password combination');
      });
  };

  return (
    <Wrapper>
      <a href="https://www.reviewsjet.com">
        <ReviewsJetCrownLogo />
      </a>
      <AuthTitle>Log in</AuthTitle>

      {/* <LoginButtonWrapper>
        <LoginButton
          href="https://www.wix.com/app-market/reviewsjet"
          target="_blank"
        >
          <FaWix size={30} /> | Login with Wix
        </LoginButton>
        <LoginButton
          href="https://www.wix.com/app-market/reviewsjet"
          target="_blank"
        >
          <FaShopify size={25} /> | Login with Shopify
        </LoginButton>
      </LoginButtonWrapper>

      <Divider>or</Divider> */}

      <AuthForm
        fluid
        ref={formRef}
        onChange={setFormValue}
        formValue={formValue}
        model={model}
      >
        <AuthFormGroup controlId="password">
          <AuthLabel>Email</AuthLabel>
          <AuthTextField
            name="email"
            type="text"
            autoComplete="true"
            placeholder="Enter email"
          />
        </AuthFormGroup>
        <AuthFormGroup controlId="password">
          <AuthIconWrapper isLoginPage onClick={toggleVisibility}>
            {visible ? <EyeIcon /> : <EyeSlashIcon />}
          </AuthIconWrapper>
          <AuthLabel>Password</AuthLabel>
          <AuthTextField
            name="password"
            type={visible ? 'text' : 'password'}
            autoComplete="true"
            placeholder={visible ? 'john@doe.com' : '*************'}
          />
        </AuthFormGroup>
        <Form.Group>
          <ButtonToolbar>
            <Button
              block
              size="lg"
              color="violet"
              type="submit"
              onClick={handleSubmit}
              disabled={loading === Loading.PENDING}
              appearance={loading === Loading.PENDING ? 'default' : 'primary'}
            >
              {loading === Loading.PENDING ? 'Please wait...' : 'Sign In'}
            </Button>
          </ButtonToolbar>
        </Form.Group>

        <StyledFlexContainer gap="10px">
          <Link to="/register">Sign up</Link> <span>|</span>{' '}
          <Link to="/forgot-password">Forgot password?</Link>
        </StyledFlexContainer>
      </AuthForm>
    </Wrapper>
  );
};

export default LoginPage;
