import { useState } from 'react';
import { BiBorderRadius } from 'react-icons/bi';
import { GiStoneWall } from 'react-icons/gi';
import { MdOutlineFormatColorText } from 'react-icons/md';
import { Divider, RadioTile, RadioTileGroup } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import BackgroundSettings from './BackgroundSettings';
import BorderSettings from './BorderSettings';
import ShadowSettings from './ShadowSettings';
import TextSettings from './TextSettings';

type SettingsOption = 'border' | 'shadow' | 'background' | 'text';

const Wrapper = styled.div`
  padding-top: 20px;
  .rs-stack {
    flex-direction: row !important;
  }
`;

const StyledRadioTile = styled(RadioTile)`
  display: block;
  width: 23%;
  border-width: 1px;
  font-weight: 500;
  text-align: center;
  padding: 8px 0;
  .rs-radio-tile-content {
    gap: 5px;
    flex-direction: column;
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    border-width: 1px !important;
  }
  .rs-radio-tile-label {
    font-weight: 500;
  }
`;

const Options = styled.div`
  width: 25%;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  gap: 5px;
  border-radius: 5px;
  border: 1px solid #dedede;
  &.active {
    color: #324fbe;
    border-color: #324fbe;
    background-color: rgb(250 251 255);
    font-weight: bold;
  }
`;
const OptionsWrapper = styled(FlexContainer)`
  margin-top: 10px;
  margin-bottom: 20px;
`;

const MoreCustomization = () => {
  const [selectedOption, setSelectedOption] =
    useState<SettingsOption>('border');

  return (
    <Wrapper>
      <RadioTileGroup defaultValue="border">
        <StyledRadioTile
          value="border"
          onClick={() => setSelectedOption('border')}
        >
          <BiBorderRadius size={25} />
          <span>Border</span>
        </StyledRadioTile>
        {/* <StyledRadioTile
          value="shadow"
          onClick={() => setSelectedOption('shadow')}
        >
          <FaCloud size={25} />
          <span>Shadow</span>
        </StyledRadioTile> */}
        <StyledRadioTile
          value="background"
          onClick={() => setSelectedOption('background')}
        >
          <GiStoneWall size={25} />
          <span>Background</span>
        </StyledRadioTile>
        <StyledRadioTile value="text" onClick={() => setSelectedOption('text')}>
          <MdOutlineFormatColorText size={25} />
          <span>Text</span>
        </StyledRadioTile>
      </RadioTileGroup>
      <Divider />
      {selectedOption === 'border' && <BorderSettings />}
      {selectedOption === 'shadow' && <ShadowSettings />}
      {selectedOption === 'background' && <BackgroundSettings />}
      {selectedOption === 'text' && <TextSettings />}
    </Wrapper>
  );
};

export default MoreCustomization;
