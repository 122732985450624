import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReviewsJetPlatform } from '../../features/product/types';
import { accountSelector } from '../../features/userData/redux/userData.slice';
import { useAppSelector } from '../../store';
import { Loading } from '../types';

/* eslint no-console: "warn" */

const useToggleSidebar = () => {
  const { pathname } = useLocation();
  const { loading, account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { platform } = selectedSite ?? {};
  const [hideSidebar, setHideSidebar] = useState(true);
  const [collapsedSidebar, setCollapsedSidbar] = useState(true);

  useEffect(() => {
    if (loading === Loading.PENDING) {
      setCollapsedSidbar(true);
      return setHideSidebar(true);
    } else if (platform === ReviewsJetPlatform.WIX) {
      setCollapsedSidbar(true);
    } else {
      // setCollapsedSidbar(false);
    }

    setHideSidebar(
      ['/plans', '/dashboard', '/settings', '/successful-payment'].includes(
        pathname
      )
    );
  }, [pathname, loading, account]);

  return { hideSidebar, collapsedSidebar, setCollapsedSidbar };
};

export default useToggleSidebar;
