import { ProductResponse } from './../types';
import { createSlice } from '@reduxjs/toolkit';
import { Loading } from '../../../shared/types';
import { RootState } from '../../../store';
import { PRODUCT_ITEMS_PER_PAGE } from '../../review/utils/constants';
import {
  convertToProductReview,
  fetchMoreProducts,
  fetchProducts,
} from './action';

type ProductsState = {
  loadingProducts: Loading;
  loadingMoreProducts: Loading;
  hasNextProductPage: boolean;
  products: ProductResponse | null;
  loadingProductConvert: Loading;
};

const initialState: ProductsState = {
  loadingProducts: Loading.INITIAL,
  loadingMoreProducts: Loading.INITIAL,
  hasNextProductPage: true,
  products: null,
  loadingProductConvert: Loading.INITIAL,
};

const product = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchProducts.pending,
      (
        state,
        {
          meta: {
            arg: { showPreloader },
          },
        }
      ) => {
        if (showPreloader) {
          state.loadingProducts = Loading.PENDING;
        }
      }
    );
    builder.addCase(fetchProducts.fulfilled, (state, { payload }) => {
      state.loadingProducts = Loading.SUCCESS;
      state.products = payload;
      state.hasNextProductPage =
        payload.products.length >= PRODUCT_ITEMS_PER_PAGE;
    });
    builder.addCase(fetchProducts.rejected, (state) => {
      state.loadingProducts = Loading.ERROR;
    });

    builder.addCase(fetchMoreProducts.pending, (state) => {
      state.loadingMoreProducts = Loading.PENDING;
    });
    builder.addCase(fetchMoreProducts.fulfilled, (state, { payload }) => {
      state.loadingMoreProducts = Loading.SUCCESS;
      const updates = {
        products: [...state.products?.products!, ...payload.products],
        metadata: payload.metadata,
        totalResults: payload.totalResults,
      };
      state.products = updates;
      state.hasNextProductPage =
        payload.products.length >= PRODUCT_ITEMS_PER_PAGE;
    });
    builder.addCase(fetchMoreProducts.rejected, (state) => {
      state.loadingMoreProducts = Loading.ERROR;
    });

    builder.addCase(convertToProductReview.pending, (state) => {
      state.loadingProductConvert = Loading.PENDING;
    });
    builder.addCase(convertToProductReview.fulfilled, (state, { payload }) => {
      state.loadingProductConvert = Loading.SUCCESS;
    });
    builder.addCase(convertToProductReview.rejected, (state) => {
      state.loadingProductConvert = Loading.ERROR;
    });
  },
});

export const productSelector = (state: RootState) => state.product;

export default product.reducer;
