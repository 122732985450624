import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, InputPicker, Message, Uploader } from 'rsuite';
import styled from 'styled-components';
import { deleteFile, getFileUploadUrl } from '../../../api/files';
import Http, { getHeaders } from '../../../services/http';
import { useNotification } from '../../../shared/hooks/useNotification';
import AddIcon from '../../../shared/svgs/add-icon';
import { RemoteFilePath } from '../../../shared/types';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { useAppSelector } from '../../../store';
import { accountSelector } from '../../userData/redux/userData.slice';

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Label = styled.label`
  display: block;
  color: black;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;
  margin-top: 30px;
`;
const Feedback = styled.textarea<{ error: boolean }>`
  height: 156px;

  border-radius: 10px;
  padding: 17px 18px;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  border: 1px solid #e5e5ea;
  outline: none;
  resize: none;
  ${({ error }) => error && 'border: 1px solid red'};
`;
// const Button = styled.button<{ disabled: boolean }>`
//   padding: 14px 40px;
//   min-width: 194px;
//   height: 47px;
//   background: ${({ disabled }) => (disabled ? '#a7a4a4' : '#000000')};
//   color: #ffffff;
//   border-radius: 33px;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 19px;
//   -webkit-transition-duration: 0.4s;
//   transition-duration: 0.4s;
//   font-size: 15px;
// `;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
  gap: 10px;
`;
const UploaderContent = styled.div`
  height: 80px;
  display: flex !important;
  background: #f5f5f5 !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 200px;
  cursor: pointer;
  max-width: 30px;
`;
const ChooseFile = styled.span`
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
`;
const ErroMessage = styled.span`
  color: red;
`;

type FormValues = {
  feedbackType: string;
  message: string;
  screenshotUrl: string;
};
interface Props {
  openDrawer: boolean;
  onDrawerClose: () => void;
}

enum FeedbackType {
  WIDGET_INSTALLATION = 'Widget Installation',
  REVIEWS_IMPORTATION = 'Reviews Importation',
  FEATURE = 'Feature request',
  BUG = 'Bug report',
  QUESTION = 'Question',
  SOMETHING_ELSE = 'Something else',
}

const http = new Http();

const FeedbackDrawer = ({ openDrawer, onDrawerClose }: Props) => {
  const { t } = useTranslation('common');
  const { showErrorToast, showSuccessToast } = useNotification();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [showUploader, setShowUploader] = useState<boolean>(true);
  const FeedbackTypeSelector = Object.values(FeedbackType).map((item) => {
    return {
      label: t(
        `feedback.${
          Object.keys(FeedbackType)[Object.values(FeedbackType).indexOf(item)]
        }`
      ),
      value: item,
    };
  });
  const { account } = useAppSelector(accountSelector);
  const { ownerEmail } = account ?? {};

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      feedbackType: FeedbackTypeSelector[0].label,
      message: '',
      screenshotUrl: '',
    },
  });

  const handleScreenshotRemove = (fileName: string) => {
    deleteFile({
      fileName,
      remotePath: RemoteFilePath.WIX_FEEDBACK,
    })
      .then(() => {
        showSuccessToast('Screenshot successfully removed');
      })
      .catch(() => {
        showErrorToast('Error removing screenshot');
      });
  };

  const onSubmit = handleSubmit(async (data) => {
    setSubmitting(true);
    try {
      await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/feedback`,
        { ...data }
      );
      showSuccessToast('Feedback successfully submitted');
      reset();
      onDrawerClose();
    } catch (e) {
      showErrorToast('Failed submitting feedback');
    } finally {
      setSubmitting(false);
    }
  });

  return (
    <Drawer open={openDrawer} onClose={onDrawerClose} className="feedback-form">
      <Drawer.Header>
        <Drawer.Title>{t('feedback.we-value-your-feedback')}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Message bordered>
          <span>
            We'll email <b>{ownerEmail}</b> in response to your query. Check
            your spam if you haven't heard back within an hour.
          </span>
        </Message>
        <FormWrapper onSubmit={onSubmit}>
          <Label>{t('feedback.what-is-your-feedback-about')}</Label>
          <Controller
            name="feedbackType"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <InputPicker
                size="md"
                style={{ width: '100%' }}
                data={FeedbackTypeSelector}
                placeholder={t('feedback.feedback')}
                {...field}
              />
            )}
          />
          {errors.feedbackType && (
            <ErroMessage>Feedback Type is required</ErroMessage>
          )}

          <Label>Your feedback</Label>
          <Controller
            name="message"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <Feedback
                error={Boolean(errors.message)}
                placeholder={t('feedback.content')}
                {...field}
              />
            )}
          />
          {errors.message && (
            <ErroMessage>
              {t('reviews.offline-review.message-is-required')}
            </ErroMessage>
          )}

          <Label>
            {showUploader
              ? t('feedback.add-screenshot')
              : t('feedback.your-screenshot')}
          </Label>
          <Uploader
            disabled={submitting || uploading}
            multiple={false}
            accept="image/*"
            action={getFileUploadUrl({
              remotePath: RemoteFilePath.WIX_FEEDBACK,
            })}
            draggable
            onSuccess={({ url }) => {
              setValue('screenshotUrl', url);
              setUploading(false);
              setShowUploader(false);
            }}
            onUpload={() => {
              setUploading(true);
            }}
            onError={() => {
              setUploading(false);
            }}
            onRemove={(file) => {
              handleScreenshotRemove(file?.blobFile?.name || '');
              setShowUploader(true);
            }}
            headers={getHeaders()}
          >
            {showUploader ? (
              <UploaderContent>
                <AddIcon />
                <ChooseFile>{t('feedback.choose-file')}</ChooseFile>
              </UploaderContent>
            ) : (
              <div style={{ display: 'none' }} />
            )}
          </Uploader>
          <ButtonWrapper>
            <Button appearance="subtle" onClick={onDrawerClose}>
              Cancel
            </Button>
            <Button appearance="primary" type="submit" disabled={submitting}>
              {t('feedback.send')}
            </Button>
          </ButtonWrapper>
        </FormWrapper>
      </Drawer.Body>
    </Drawer>
  );
};

export default FeedbackDrawer;
