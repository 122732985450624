import { createAsyncThunk } from '@reduxjs/toolkit';
import Http from '../../../services/http';
import { Loading } from '../../../shared/types';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { RootState } from '../../../store';

const http = new Http();

/* eslint no-console: "warn" */

export const fetchPublicShareLinkSettings = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>(
  'public-share-link',
  async (siteId, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/public-share-link`,
        { siteId }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        publicShareLink: { loading },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);
