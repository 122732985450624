import { createAsyncThunk } from '@reduxjs/toolkit';
import lodash from 'lodash';
import Http from '../../../services/http';
import {
  Loading,
  ReviewerPlatform,
  SubscriptionPlan,
} from '../../../shared/types';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { RootState } from '../../../store';
import { SettingsCategory, SiteWidgetSettings } from './widgetSettings.slice';

/* eslint no-console: "warn" */

type SetAutoPublishParams = {
  status: boolean;
  value: string;
  submissionType: 'reviewsjet' | 'thirdparty';
  submitReviewsTo: string[];
};

type UpdateSiteUrlParams = {
  url: string;
};

const http = new Http();

export const fetchConnectedThirdPartyAccounts = createAsyncThunk<
  any,
  void,
  { rejectValue: string; state: RootState }
>('settings/third-party/connected', async (_args, { rejectWithValue }) => {
  try {
    const response = await http.get(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/settings/third-party/connected`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const disConnectThirdPartyAccount = createAsyncThunk<
  any,
  { placeId: string; dataId?: string; platform: ReviewerPlatform },
  { rejectValue: string; state: RootState }
>(
  'settings/disconnected',
  async ({ placeId, dataId, platform }, { rejectWithValue }) => {
    let url = '';

    switch (platform) {
      case ReviewerPlatform.GOOGLE:
        url = `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/google/account/${placeId}/${dataId}`;
        break;
      case ReviewerPlatform.YELP:
        url = `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/yelp/${placeId}`;
        break;

      default:
        break;
    }

    try {
      const response = await http.delete(url);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);
export const fetchWidgetSettings = createAsyncThunk<
  any,
  void,
  { rejectValue: string; state: RootState }
>(
  'settings/widget-settings',
  async (_args, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/widget-settings`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        setting: { loadingSiteWidgetSettings },
      } = api.getState();

      return loadingSiteWidgetSettings !== Loading.PENDING;
    },
  }
);

export const updateWidgetSettingsRequest = createAsyncThunk<
  any,
  void,
  { rejectValue: string; state: RootState }
>(
  'settings/update/widget-settings',
  async (_args, { rejectWithValue, getState }) => {
    const {
      userData: { account },
      setting: { siteWidgetSettings, currentSettingsPage },
    } = getState();

    const { selectedSite } = account ?? {};
    const { plan } = selectedSite || {};

    let fields: string[] = [];
    let updatedSettings = siteWidgetSettings;

    const isPremium = SubscriptionPlan.FREE !== plan!;

    switch (currentSettingsPage) {
      case SettingsCategory.SITE: {
        fields = [
          'showOnDesktop',
          'position',
          'isSticky',
          'showReviewsAverage',
          'widgetType',
          'mobileWidgetType',
          'mobileWidgetPosition',
          'showBusinessReviewsInModal',
          'showProductReviewsInModal',
          'showOnMobile',
        ];

        if (!isPremium) {
          // @ts-ignore
          updatedSettings = {
            ...updatedSettings,
            // showProductReviewsInModal: false,
            // showOnMobile: false,
          };
        }
        break;
      }
      case SettingsCategory.EMBEDDED_WIDGET: {
        fields = [
          'simpleTestimonialWidget',
          'starColor',
          'secondaryColor',
          'borderWidth',
          'borderColor',
          'acceptReviewsEmbeddedWidget',
          'showOverallRatingEmbeddedWidget',
        ];

        if (isPremium) {
          fields = [...fields, 'mixWithProductReviewsInEmbedded'];
        }
        break;
      }
      case SettingsCategory.CAROUSEL_WIDGET: {
        fields = [
          'starColor',
          'showOnMobile',
          'sliderWidget',
          'secondaryColor',
          'autoplayCarousel',
          'mixWithProductReviewsInEmbedded',
          'carouselType',
        ];

        // if (!isPremium) {
        //   updatedSettings = {
        //     ...updatedSettings,
        //     mixWithProductReviewsInEmbedded: false,
        //     showOnMobile: false,
        //   };
        // }
        break;
      }
      case SettingsCategory.RICH_SNIPPETS: {
        if (isPremium) {
          fields = ['activateRichSnippets'];
        }
        break;
      }
    }

    const payload = lodash.pick(updatedSettings, fields);

    try {
      const response = await http.update(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/widget-settings/${siteWidgetSettings?._id}`,
        payload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const resetWidgetSettings = createAsyncThunk<
  any,
  void,
  { rejectValue: string; state: RootState }
>(
  'settings/restore/widget-settings',
  async (_args, { rejectWithValue, getState }) => {
    const {
      setting: { siteWidgetSettings },
    } = getState();

    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/widget-settings/restore`,
        siteWidgetSettings as object
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const setAutoPublish = createAsyncThunk<
  any,
  SetAutoPublishParams,
  { rejectValue: string; state: RootState }
>('settings/review-settings', async (input, { rejectWithValue }) => {
  const { status, value, submissionType, submitReviewsTo } = input;

  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/widget-settings/review-settings`,
      { status, value, submissionType, submitReviewsTo }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const updateSiteUrl = createAsyncThunk<
  any,
  UpdateSiteUrlParams,
  { rejectValue: string; state: RootState }
>('settings/website-url', async ({ url }, { rejectWithValue }) => {
  try {
    const response = await http.update(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/settings/website-url`,
      { url }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const updateWidgetsSettings = createAsyncThunk<
  SiteWidgetSettings,
  void,
  { rejectValue: string; state: RootState }
>(
  'settings/update-embedded-widget-settings',
  async (_args, { rejectWithValue, getState }) => {
    const {
      setting: { siteWidgetSettings },
    } = getState();

    if (siteWidgetSettings) {
      try {
        const response = await http.update(
          `${removeTrailingSlash(
            process.env.REACT_APP_REVIEWS_JET_API!
          )}/widget-settings/widget/${siteWidgetSettings?._id}`,
          { payload: siteWidgetSettings }
        );
        return response.data;
      } catch (error) {
        return rejectWithValue((error as any).response.data);
      }
    }
  },
  {
    condition(_, api): boolean {
      const {
        setting: {
          updateWidgetSettings: { saving },
        },
      } = api.getState();

      return saving !== Loading.PENDING;
    },
  }
);
