import axios from 'axios';
import mem from 'mem';
import localStorage from '../shared/utils/localStorage';
import setSession from '../shared/utils/setSession';

/* eslint no-console: "warn" */

const refreshTokenFn = async () => {
  const session = localStorage.get('session');

  try {
    const response = await axios.post('/app/refresh-token', {
      refreshToken: session?.jwtRefreshToken,
    });

    const { data } = response;

    if (!data?.accessToken) {
      localStorage.remove('session');
      window.location.replace(process.env.REACT_APP_REVIEWSJET_WEBSITE!);
    } else {
      const { accessToken: jwtAccessToken } = data;
      setSession({ jwtAccessToken, jwtRefreshToken: session?.jwtRefreshToken });
    }

    return data.accessToken;
  } catch (error) {
    localStorage.remove('session');
    window.location.replace(process.env.REACT_APP_REVIEWSJET_WEBSITE!);
  }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});
