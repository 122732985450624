import { createSlice } from '@reduxjs/toolkit';
import { Loading, PaginatedResponse } from '../../../shared/types';
import { RootState } from '../../../store';
import { CampaignItem, ReviewRequestItem } from '../../review/types';
import {
  deleteReviewsRequest,
  fetchCampaigns,
  fetchReviewRequests,
  getAutomaticReviewRequest,
  getShopifyOrders,
  getTotalCampaignsThisMonth,
  getWixOrders,
  saveReviewRequest,
  sendReviewRequestEmail,
  sendReviewRequestTestEmail,
  toggleReviewRequest,
} from './action';

/* eslint no-console: "warn" */

export enum RequestPageType {
  BUSINESS = 'business',
  PRODUCT = 'product',
  AUTOMATIC = 'automatic',
}

export enum ReviewRequestType {
  ONE_TIME = 'one-time',
  AUTOMATIC = 'automatic',
}

type CampaignState = {
  campaigns: {
    loading: Loading;
    items: PaginatedResponse<CampaignItem> | null;
    selectedReviewsRequest: ReviewRequestItem | null;
  };
  reviewRequests: {
    loading: Loading;
    saving: Loading;
    items: PaginatedResponse<ReviewRequestItem> | null;
  };
  automaticReviewRequest: {
    item: ReviewRequestItem | null;
    loading: Loading;
  };
  totalCampaignsThisMonth: {
    loading: Loading;
    value: number;
  };
  selectedReviewRequest: ReviewRequestItem | null;
  openReviewsRequestDrawer: ReviewRequestType | null;
  currentRequestPage: RequestPageType | null;
  deleteReviewsRequestLoading: Loading;
  sendingReviewRequestEmail: Loading;
  sendingReviewRequestTestEmail: Loading;
  toggleReviewRequestLoading: Loading;
  wixOrdersLoading: Loading;
  wixOrdersMoreLoading: Loading;
  shopifyOrdersLoading: Loading;
};

const initialState: CampaignState = {
  campaigns: {
    items: null,
    loading: Loading.INITIAL,
    selectedReviewsRequest: null,
  },
  reviewRequests: {
    loading: Loading.INITIAL,
    saving: Loading.INITIAL,
    items: null,
  },
  automaticReviewRequest: {
    item: null,
    loading: Loading.INITIAL,
  },
  totalCampaignsThisMonth: {
    loading: Loading.INITIAL,
    value: 0,
  },
  selectedReviewRequest: null,
  openReviewsRequestDrawer: null,
  currentRequestPage: null,
  deleteReviewsRequestLoading: Loading.INITIAL,
  sendingReviewRequestEmail: Loading.INITIAL,
  sendingReviewRequestTestEmail: Loading.INITIAL,
  toggleReviewRequestLoading: Loading.INITIAL,
  wixOrdersLoading: Loading.INITIAL,
  wixOrdersMoreLoading: Loading.INITIAL,
  shopifyOrdersLoading: Loading.INITIAL,
};

const review = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setOpenReviewsRequestDrawer: (
      state,
      { payload }: { payload: ReviewRequestType | null }
    ) => {
      state.openReviewsRequestDrawer = payload;
    },
    setCurrentRequestPage: (state, { payload }) => {
      state.currentRequestPage = payload;
    },
    setSelectedReviewRequest: (state, { payload }) => {
      state.selectedReviewRequest = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveReviewRequest.pending, (state) => {
      state.reviewRequests.saving = Loading.PENDING;
    });
    builder.addCase(saveReviewRequest.rejected, (state) => {
      state.reviewRequests.saving = Loading.ERROR;
    });
    builder.addCase(saveReviewRequest.fulfilled, (state) => {
      state.reviewRequests.saving = Loading.SUCCESS;
    });

    builder.addCase(fetchCampaigns.pending, (state) => {
      state.campaigns.loading = Loading.PENDING;
    });
    builder.addCase(fetchCampaigns.rejected, (state) => {
      state.campaigns.loading = Loading.ERROR;
    });
    builder.addCase(fetchCampaigns.fulfilled, (state, { payload }) => {
      state.campaigns.loading = Loading.SUCCESS;
      state.campaigns.items = payload.campaigns;
      state.campaigns.selectedReviewsRequest = payload.reviewsRequest;
    });

    builder.addCase(fetchReviewRequests.pending, (state) => {
      state.reviewRequests.loading = Loading.PENDING;
    });
    builder.addCase(fetchReviewRequests.rejected, (state) => {
      state.reviewRequests.loading = Loading.ERROR;
    });
    builder.addCase(fetchReviewRequests.fulfilled, (state, { payload }) => {
      state.reviewRequests.loading = Loading.SUCCESS;
      state.reviewRequests.items = payload.reviewRequests;
    });

    builder.addCase(deleteReviewsRequest.pending, (state) => {
      state.deleteReviewsRequestLoading = Loading.PENDING;
    });
    builder.addCase(deleteReviewsRequest.rejected, (state) => {
      state.deleteReviewsRequestLoading = Loading.ERROR;
    });
    builder.addCase(deleteReviewsRequest.fulfilled, (state) => {
      state.deleteReviewsRequestLoading = Loading.SUCCESS;
    });

    builder.addCase(sendReviewRequestTestEmail.pending, (state) => {
      state.sendingReviewRequestTestEmail = Loading.PENDING;
    });
    builder.addCase(sendReviewRequestTestEmail.rejected, (state) => {
      state.sendingReviewRequestTestEmail = Loading.ERROR;
    });
    builder.addCase(sendReviewRequestTestEmail.fulfilled, (state) => {
      state.sendingReviewRequestTestEmail = Loading.SUCCESS;
    });

    builder.addCase(sendReviewRequestEmail.pending, (state) => {
      state.sendingReviewRequestEmail = Loading.PENDING;
    });
    builder.addCase(sendReviewRequestEmail.rejected, (state) => {
      state.sendingReviewRequestEmail = Loading.ERROR;
    });
    builder.addCase(sendReviewRequestEmail.fulfilled, (state) => {
      state.sendingReviewRequestEmail = Loading.SUCCESS;
    });
    builder.addCase(getTotalCampaignsThisMonth.pending, (state) => {
      state.totalCampaignsThisMonth.loading = Loading.PENDING;
    });
    builder.addCase(getTotalCampaignsThisMonth.rejected, (state) => {
      state.totalCampaignsThisMonth.loading = Loading.ERROR;
    });
    builder.addCase(
      getTotalCampaignsThisMonth.fulfilled,
      (state, { payload }) => {
        state.totalCampaignsThisMonth.loading = Loading.SUCCESS;
        state.totalCampaignsThisMonth.value = payload.totalSent;
      }
    );
    builder.addCase(toggleReviewRequest.fulfilled, (state) => {
      state.toggleReviewRequestLoading = Loading.SUCCESS;
    });
    builder.addCase(toggleReviewRequest.pending, (state) => {
      state.toggleReviewRequestLoading = Loading.PENDING;
    });
    builder.addCase(toggleReviewRequest.rejected, (state) => {
      state.toggleReviewRequestLoading = Loading.ERROR;
    });

    builder.addCase(
      getAutomaticReviewRequest.fulfilled,
      (state, { payload }) => {
        state.automaticReviewRequest.loading = Loading.SUCCESS;
        state.automaticReviewRequest.item = payload.reviewsRequest;
      }
    );
    builder.addCase(getAutomaticReviewRequest.pending, (state) => {
      state.automaticReviewRequest.loading = Loading.PENDING;
    });
    builder.addCase(getAutomaticReviewRequest.rejected, (state) => {
      state.automaticReviewRequest.loading = Loading.ERROR;
    });
    // Wix Orders
    builder.addCase(
      getWixOrders.fulfilled,
      (
        state,
        {
          meta: {
            arg: { cursor },
          },
        }
      ) => {
        if (cursor) {
          state.wixOrdersMoreLoading = Loading.SUCCESS;
        } else {
          state.wixOrdersLoading = Loading.SUCCESS;
        }
      }
    );
    builder.addCase(
      getWixOrders.pending,
      (
        state,
        {
          meta: {
            arg: { cursor },
          },
        }
      ) => {
        if (cursor) {
          state.wixOrdersMoreLoading = Loading.PENDING;
        } else {
          state.wixOrdersLoading = Loading.PENDING;
        }
      }
    );
    builder.addCase(
      getWixOrders.rejected,
      (
        state,
        {
          meta: {
            arg: { cursor },
          },
        }
      ) => {
        if (cursor) {
          state.wixOrdersMoreLoading = Loading.ERROR;
        } else {
          state.wixOrdersLoading = Loading.ERROR;
        }
      }
    );
    // Shopify Orders
    builder.addCase(getShopifyOrders.fulfilled, (state) => {
      state.shopifyOrdersLoading = Loading.SUCCESS;
    });
    builder.addCase(getShopifyOrders.pending, (state) => {
      state.shopifyOrdersLoading = Loading.PENDING;
    });
    builder.addCase(getShopifyOrders.rejected, (state) => {
      state.shopifyOrdersLoading = Loading.ERROR;
    });
  },
});

export const {
  setOpenReviewsRequestDrawer,
  setCurrentRequestPage,
  setSelectedReviewRequest,
} = review.actions;

export const campaignSelector = (state: RootState) => state.campaign;
export const automaticReviewRequestSelector = (state: RootState) =>
  state.campaign.automaticReviewRequest.item;

export default review.reducer;
