export const YELP_LOCATIONS_PER_PAGE = 10;
export const GOOGLE_BUSINESSESS_PER_PAGE = 10;
export const GOOGLE_APIS_BASE_URL = 'https://www.googleapis.com';
export const VALID_PRODUCTHUNT_DOMAIN = 'www.producthunt.com';
export const VALID_CAPTERRA_DOMAIN = 'www.capterra.com';
export const VALID_TRIPADVISOR_DOMAIN = 'www.tripadvisor.com';
export const VALID_BOOKING_DOMAIN = 'booking.com';
export const VALID_TRUSTPILOT_DOMAINS = [
  'www.trustpilot.com',
  'ch.trustpilot.com',
  'fr.trustpilot.com',
  'de.trustpilot.com',
];
export const VALID_AMAZON_DOMAINS = [
  'amazon.com',
  'amazon.ca',
  'amazon.com.mx',
  'amazon.co.uk',
  'amazon.fr',
  'amazon.de',
  'amazon.it',
  'amazon.es',
  'amazon.in',
  'amazon.jp',
  'amazon.nl',
  'amazon.com.tr',
  'amazon.sa',
  'amazon.ae',
  'amazon.au',
  'amazon.sg',
  'amazon.com.br',
];

export const VALID_AIRBNB_DOMAINS = [
  'airbnb.ca',
  'airbnb.ca',
  'airbnb.co.uk',
  'airbnb.com.au',
  'airbnb.de',
  'airbnb.fr',
  'airbnb.it',
  'airbnb.es',
  'airbnb.co.in',
  'airbnb.jp',
  'airbnb.cn',
  'airbnb.com',
  'airbnb.cat',
];
