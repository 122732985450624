import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import campaign from '../features/campaign/redux/campaign.slice';
import webflow from '../features/external/redux/webflow.slice';
import incentive from '../features/incentives/redux/incentive.slice';
import integrations from '../features/integrations/redux/integrations.slice';
import reviews from '../features/manage-reviews/redux/reviews.slice';
import product from '../features/product/redux/product.slice';
import publicShareLink from '../features/public-share-link/redux/share-link.slice';
import review from '../features/review/redux/review.slice';
import userData from '../features/userData/redux/userData.slice';
import setting from '../features/widgetSettings/redux/widgetSettings.slice';
import app from './app.slice';
import appHistory from './appHistory';

const reducers = combineReducers({
  app,
  review,
  webflow,
  setting,
  reviews,
  userData,
  product,
  campaign,
  incentive,
  integrations,
  publicShareLink,
  router: connectRouter(appHistory),
});

export default reducers;
