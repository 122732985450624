import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Form, InputNumber, Radio, RadioGroup, Toggle } from 'rsuite';
import { ValueType } from 'rsuite/esm/Radio';
import styled from 'styled-components';
import ColorPicker from '../../../../../shared/components/colorPicker';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import { useAppSelector } from '../../../../../store';
import FormRow from '../../../components/form-row';
import useDispatchUpdateSiteWidgetSettings from '../../../hooks/useDispatchUpdateSiteWidgetSettings';
import { settingSelector } from '../../../redux/widgetSettings.slice';

const StyledRadioGroup = styled(RadioGroup)`
  width: 100%;
  justify-content: space-between;
`;

const BorderSettings = () => {
  const { t } = useTranslation('common');
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { dispatchUpdateSiteWidgetSettings } =
    useDispatchUpdateSiteWidgetSettings();

  return (
    <>
      <FormRow justifyContent="flex-start" gap="10px">
        Show border
        <Toggle
          size="md"
          checked={!!siteWidgetSettings?.carouselSliderWidget.showBorder}
          checkedChildren={t('widget-settings.product.yes')}
          unCheckedChildren={t('widget-settings.product.no')}
          onClick={() => {
            dispatchUpdateSiteWidgetSettings(
              'showBorder',
              !siteWidgetSettings?.carouselSliderWidget.showBorder,
              'slider'
            );
          }}
        />
      </FormRow>
      {siteWidgetSettings?.carouselSliderWidget.showBorder && (
        <>
          <Divider />
          <FlexContainer justifyContent="space-between">
            <FormRow justifyContent="flex-start" gap="10px">
              Border color
              <ColorPicker
                color={siteWidgetSettings?.carouselSliderWidget.borderColor}
                changeColor={(color: string) =>
                  dispatchUpdateSiteWidgetSettings(
                    'borderColor',
                    color,
                    'slider'
                  )
                }
              />
            </FormRow>
            <FormRow justifyContent="flex-end" gap="10px">
              <span>Border width</span>
              <InputNumber
                max={10}
                min={1}
                value={siteWidgetSettings.carouselSliderWidget.borderWidth}
                size="sm"
                style={{ width: 100 }}
                postfix="px"
                onChange={(
                  value: number | string | null,
                  event: SyntheticEvent<Element, Event>
                ) => {
                  dispatchUpdateSiteWidgetSettings(
                    'borderWidth',
                    value,
                    'slider'
                  );
                }}
              />
            </FormRow>
          </FlexContainer>
          <Divider />
        </>
      )}

      <>
        Border radius
        <FormRow justifyContent="space-between" gap="10px">
          <Form.Group controlId="radioList" style={{ width: '100%' }}>
            <StyledRadioGroup
              name="radioList"
              inline
              appearance="default"
              value={siteWidgetSettings?.carouselSliderWidget.borderRadius}
              onChange={(value: ValueType | undefined, _: boolean) => {
                dispatchUpdateSiteWidgetSettings(
                  'borderRadius',
                  value,
                  'slider'
                );
              }}
            >
              <Radio value="">None</Radio>
              <Radio value="8px">Small</Radio>
              <Radio value="12px">Medium</Radio>
              <Radio value="24px">Large</Radio>
            </StyledRadioGroup>
          </Form.Group>
        </FormRow>
      </>
    </>
  );
};

export default BorderSettings;
