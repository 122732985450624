import { createSlice } from '@reduxjs/toolkit';
import {
  Loading,
  SubscriptionPlan,
  ThirdPartyEngine,
} from '../../../shared/types';
import { RootState } from '../../../store';
import { PreviewType, WidgetPageTypes } from '../types';
import {
  disConnectThirdPartyAccount,
  fetchConnectedThirdPartyAccounts,
  fetchWidgetSettings,
  resetWidgetSettings,
  setAutoPublish,
  updateWidgetsSettings,
} from './action';

export type WidgetType =
  | 'carousel'
  | 'wall-of-love'
  | 'modal-reviews'
  | 'product-widget'
  | 'star-width';

export type SettingsType = 'desktop' | 'mobile';

export enum ReviewsTab {
  BUSINESS_TAB = 'BUSINESS_TAB',
  PRODUCT_TAB = 'PRODUCT_TAB',
}

export enum WallOfLove {
  SIMPLE = 'simple',
}

export enum ModalReviewWidgetType {
  DEFAULT = 'default',
  MINI = 'mini',
  ADVANCED = 'advanced',
}
export enum WidgetPosition {
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM_LEFT = 'bottom_left',
  BOTTOM_RIGHT = 'bottom_right',
  CENTER = 'center',
  BOTTOM = 'bottom',
}
export enum MobileTabWidgetPosition {
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM_LEFT = 'bottom_left',
  BOTTOM_RIGHT = 'bottom_right',
  BOTTOM = 'bottom',
}
export enum CarouselType {
  NORMAL = 'normal',
  MARQUEE = 'marquee',
}
export type TranslationType = {
  widgetTitle: string;
  helpful: string;
  readMore: string;
  readLess: string;
  loading: string;
  loadMoreReviews: string;
  emptyStateMessage: string;
  widgetButtonText: string;
  verifiedReviewer: string;
  backToReviews: string;
  thankYouTitle: string;
  thankYouMessage: string;
  pleaseWait: string;
  totalReviews: string;
  productWidgetTitle: string;
  productReviewsTab: string;
  businessReviewsTab: string;
  viewProduct: string;
  responseFromTheOwner: string;
  fiveStars: string;
  fourStars: string;
  threeStars: string;
  twoStars: string;
  oneStar: string;
  sortBy: string;
  filterBy: string;
  mostRecent: string;
  topReviews: string;
  allStars: string;
  fiveStarsOnly: string;
  fourStarsOnly: string;
  threeStarsOnly: string;
  twoStarsOnly: string;
  oneStarOnly: string;
  viewReview: string;
  productReviewsEmptyState: string;
  businessReviewsEmptyState: string;
  happyCustomersTitle: string;
  reviewForm: {
    title: string;
    description: string;
    addReview: string;
    messagePlaceholder: string;
    messageErrorMessage: string;
    reviewerSectionTitle: string;
    namePlaceHolder: string;
    nameErrorMessage: string;
    emailPlaceHolder: string;
    emailErrorMessage: string;
    submitButtonText: string;
    cancel: string;
    profilePicture: string;
    clickHereToUpload: string;
    addUpToThreePhotos: string;
    submitYourReviewOn: string;
    enterReviewTitle: string;
  };
};
export enum ProductWidgetType {
  DEFAULT = 'default',
  GRID = 'grid',
}
export enum TestimonialWidget {
  SIMPLE = 'simple',
}
export type EmbeddedWidgetType = {
  type: TestimonialWidget;
  visibleOnMobile: boolean;
  visibleOnDesktop: boolean;
  randomReviews: boolean;
  numberOfReviews: number;
  showLoadMoreReviews: boolean;
  reviewCardColor: string;
  borderWidth: number;
  borderColor: string;
};
export type ProductWidgetSettingsType = {
  setBackgroundColor: boolean;
  backgroundColor: string;
  textColor: string;
  buttonBorderColor: string;
  reviewsLimit: number;
};

export enum ShadowType {
  NONE = 'none',
  NORMAL = 'normal',
  FOCUS = 'focus',
}
export enum ShadowSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
export enum BackgroundType {
  TRANSPARENT = 'transparent',
  SOLID_COLOR = 'solid-color',
  GRADIENT = 'gradient',
}

export type EmbeddedWidgetSettings = {
  // border settings
  showBorder: boolean;
  borderColor: string;
  borderWidth: number;
  borderRadius: string;

  // shadow settings
  shadowType: ShadowType;
  shadowSize: ShadowSize;
  shadowColor: string;

  // background settings
  backgroundColorType: BackgroundType;
  backgroundColor: string;
  backgroundColorGradient: string;
  reviewCardColor: string;
  reviewCardBackgroundType: BackgroundType;

  // text settings
  textColor: string;
  dateColor: string;
  linkColor: string;
  starColor: string;
  buttonTextColor: string;
  buttonBackgroundColor: string;

  // other settings
  hideSourceIcon: boolean;
  showOverallRating: boolean;
  acceptReviews: boolean;
  mixWithProductReviews: boolean;
  showReviewDate: boolean;

  reviewCardColorOpacity: number;
};

export type CarouselWidgetSettings = EmbeddedWidgetSettings & {
  variant: CarouselWidget;
  autoplay: boolean;
  autoPlaySpeed: number;
  pauseOnHover: boolean;
};

export type BrandingSettings = {
  rtl: boolean;
  thankYouImage: string;
  showFullName: boolean;
  fontFamily: string;
  dateFormat: string;
  autoPublishEnabled: boolean;
  autoPublishRating: number;
  useMyWebsiteFontFamily: boolean;
  submissionType: 'reviewsjet' | 'thirdparty';
  submitReviewsTo: string[];
  displayLocation: boolean;
};

export type ModalReviewsOtherSettings = {
  showBusinessReviews: boolean;
  showProductReviews: boolean;
  isSticky: boolean;
  showReviewDate: boolean;
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
  reviewCardColor: string;
  linkColor: string;
  hideSourceIcon: boolean;
  starColor: string;
  dateColor: string;
  textColor: string;
  hideHelpfulCount: boolean;
  acceptReviews: boolean;
  buttonBackgroundColor: string;
  buttonTextColor: string;
  'desktopSettings.visible': boolean;
  'desktopSettings.variant': ModalReviewWidgetType;
  'desktopSettings.position': WidgetPosition;
  'mobileSettings.visible': boolean;
  'mobileSettings.variant': ModalReviewWidgetType;
  'mobileSettings.position': WidgetPosition;
};

export type ProductWidgetSettings = Omit<
  EmbeddedWidgetSettings,
  | 'borderRadius'
  | 'mixWithProductReviews'
  | 'acceptReviews'
  | 'showOverallRating'
  | 'hideSourceIcon'
> & {
  reviewsLimit: number;
  variant: ProductWidgetType;
  hideHelpfulCount: boolean;
};

export type ModalReviewsSettings = Omit<
  EmbeddedWidgetSettings,
  | 'mixWithProductReviews'
  | 'showOverallRating'
  | 'showBorder'
  | 'borderRadius'
  | 'shadowType'
  | 'shadowSize'
  | 'shadowColor'
  | 'backgroundColorType'
  | 'backgroundColor'
  | 'backgroundColorGradient'
  | 'reviewCardBackgroundType'
  | 'reviewCardColorOpacity'
> & {
  showBusinessReviews: boolean;
  showProductReviews: boolean;
  isSticky: boolean;
  hideHelpfulCount: boolean;
  desktopSettings: {
    visible: boolean;
    variant: ModalReviewWidgetType.DEFAULT;
    position: WidgetPosition.LEFT;
  };
  mobileSettings: {
    visible: boolean;
    variant: ModalReviewWidgetType.DEFAULT;
    position: WidgetPosition.BOTTOM;
  };
};

export type SiteWidgetSettings = {
  _id: string;
  siteId: string;
  instanceId: string;
  modalWidget: ModalReviewsSettings;
  masonryWidget: EmbeddedWidgetSettings;
  listWidget: EmbeddedWidgetSettings;
  gridWidget: EmbeddedWidgetSettings;
  carouselSliderWidget: CarouselWidgetSettings;
  carouselMarqueeWidget: EmbeddedWidgetSettings;
  carouselWidget: CarouselWidgetSettings;
  wallOfLove: EmbeddedWidgetSettings & {
    variant: WallOfLove;
  };
  productWidget: ProductWidgetSettings;
  starWidget: {
    position: WidgetPosition;
    starColor: string;
  };
  brandingSettings: BrandingSettings;
  translation: TranslationType;
};
export enum SettingsCategory {
  BRANDING = 'branding',
  PRODUCT = 'product',
  SITE = 'site',
  EMBEDDED_WIDGET = 'embedded-widget',
  CAROUSEL_WIDGET = 'carousel-widget',
  RICH_SNIPPETS = 'rich-snippets',
}
export enum CarouselWidget {
  SIMPLE = 'simple',
}
export type ConnectdAccount = {
  _id: string;
  instanceId: string;
  placeId: string;
  domain: string;
  engine: ThirdPartyEngine;
  start: number;
  created_at: string;
  updated_at: string;
  dataId?: string;
};
type ConnectedAccounts = {
  google: ConnectdAccount | null;
  yelp: ConnectdAccount | null;
  facebook: ConnectdAccount | null;
  aliExpress: ConnectdAccount | null;
  amazon: ConnectdAccount | null;
};

type WidgetSettingsState = {
  loadingConnectedThirdPartyAccounts: Loading;
  loadingSiteWidgetSettings: Loading;
  loadingSiteWidgetSettingsRestore: Loading;
  siteWidgetSettings: SiteWidgetSettings | null;
  initialSiteWidgetSettings: SiteWidgetSettings | null;
  plan: SubscriptionPlan;
  currentSettingsPage: SettingsCategory;
  connectedThirdPartyAccounts: ConnectedAccounts;
  setAutoPublishLoading: Loading;
  previewType: PreviewType;
  widgetPageType: WidgetPageTypes;
  showWidgetModal: boolean;
  updateWidgetSettings: {
    saving: Loading;
  };
  isWidgetSettingsUpdated: boolean;
};

const initialState: WidgetSettingsState = {
  loadingConnectedThirdPartyAccounts: Loading.INITIAL,
  loadingSiteWidgetSettings: Loading.INITIAL,
  loadingSiteWidgetSettingsRestore: Loading.INITIAL,
  siteWidgetSettings: null,
  initialSiteWidgetSettings: null,
  plan: SubscriptionPlan.FREE,
  currentSettingsPage: SettingsCategory.BRANDING,
  connectedThirdPartyAccounts: {
    google: null,
    yelp: null,
    facebook: null,
    aliExpress: null,
    amazon: null,
  },
  setAutoPublishLoading: Loading.INITIAL,
  previewType: PreviewType.DESKTOP,
  widgetPageType: WidgetPageTypes.REVIEW,
  showWidgetModal: false,
  updateWidgetSettings: {
    saving: Loading.INITIAL,
  },
  isWidgetSettingsUpdated: false,
};

const setting = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setCurrentSettingsPage: (state, { payload }) => {
      state.currentSettingsPage = payload;
    },
    updateSiteWidgetSettings: (state, { payload }) => {
      state.siteWidgetSettings = { ...state.siteWidgetSettings, ...payload };
    },
    updatePreviewType: (state, { payload }) => {
      state.previewType = payload;
    },
    setWidgetPageType: (state, { payload }) => {
      state.widgetPageType = payload;
    },
    setShowWidgetModal: (state, { payload }) => {
      state.showWidgetModal = payload;
    },
    setWidgetSettingsUpdated: (state) => {
      state.isWidgetSettingsUpdated = true;
    },
    resetWidgetSettingsState: (state) => {
      state.isWidgetSettingsUpdated = false;
      state.siteWidgetSettings = state.initialSiteWidgetSettings;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConnectedThirdPartyAccounts.pending, (state) => {
      state.loadingConnectedThirdPartyAccounts = Loading.PENDING;
    });
    builder.addCase(
      fetchConnectedThirdPartyAccounts.fulfilled,
      (state, { payload }) => {
        state.loadingConnectedThirdPartyAccounts = Loading.SUCCESS;
        state.connectedThirdPartyAccounts = payload;
      }
    );
    builder.addCase(fetchConnectedThirdPartyAccounts.rejected, (state) => {
      state.loadingConnectedThirdPartyAccounts = Loading.ERROR;
    });
    //
    builder.addCase(disConnectThirdPartyAccount.pending, (state) => {
      state.loadingConnectedThirdPartyAccounts = Loading.PENDING;
    });
    builder.addCase(
      disConnectThirdPartyAccount.fulfilled,
      (state, { payload }) => {
        state.loadingConnectedThirdPartyAccounts = Loading.SUCCESS;
        state.connectedThirdPartyAccounts = payload;
      }
    );
    builder.addCase(disConnectThirdPartyAccount.rejected, (state) => {
      state.loadingConnectedThirdPartyAccounts = Loading.ERROR;
    });
    builder.addCase(fetchWidgetSettings.pending, (state) => {
      state.loadingSiteWidgetSettings = Loading.PENDING;
    });
    builder.addCase(fetchWidgetSettings.fulfilled, (state, { payload }) => {
      state.loadingSiteWidgetSettings = Loading.SUCCESS;
      state.siteWidgetSettings = payload.settings;
      state.initialSiteWidgetSettings = payload.settings;
      state.plan = payload.plan;
    });
    builder.addCase(fetchWidgetSettings.rejected, (state) => {
      state.loadingSiteWidgetSettings = Loading.ERROR;
    });
    builder.addCase(updateWidgetsSettings.pending, (state) => {
      state.updateWidgetSettings.saving = Loading.PENDING;
    });
    builder.addCase(updateWidgetsSettings.fulfilled, (state, { payload }) => {
      state.siteWidgetSettings = payload;
      state.initialSiteWidgetSettings = payload;
      state.updateWidgetSettings.saving = Loading.SUCCESS;
      state.isWidgetSettingsUpdated = false;
    });
    builder.addCase(updateWidgetsSettings.rejected, (state) => {
      state.updateWidgetSettings.saving = Loading.ERROR;
    });

    builder.addCase(resetWidgetSettings.pending, (state) => {
      state.loadingSiteWidgetSettingsRestore = Loading.PENDING;
    });
    builder.addCase(resetWidgetSettings.fulfilled, (state, { payload }) => {
      state.loadingSiteWidgetSettingsRestore = Loading.SUCCESS;
      state.siteWidgetSettings = payload;
    });
    builder.addCase(resetWidgetSettings.rejected, (state) => {
      state.loadingSiteWidgetSettingsRestore = Loading.ERROR;
    });
    builder.addCase(setAutoPublish.pending, (state) => {
      state.setAutoPublishLoading = Loading.PENDING;
    });
    builder.addCase(setAutoPublish.fulfilled, (state, { payload }) => {
      state.setAutoPublishLoading = Loading.SUCCESS;
      state.siteWidgetSettings = payload;
    });
  },
});

export const {
  updateSiteWidgetSettings,
  setCurrentSettingsPage,
  updatePreviewType,
  setWidgetPageType,
  setShowWidgetModal,
  setWidgetSettingsUpdated,
  resetWidgetSettingsState,
} = setting.actions;

export const settingSelector = (state: RootState) => state.setting;
export const siteWidgetSettingsSelector = (state: RootState) =>
  state.setting.siteWidgetSettings;
export const verifiedReviewerSelector = (state: RootState) => {
  const settings = state.setting.siteWidgetSettings;
  const translation = settings?.translation;

  return translation?.verifiedReviewer ?? 'Verified Reviewer';
};

export default setting.reducer;
