import 'rsuite-color-picker/lib/styles.less';
import 'rsuite/styles/index.less';
import { createGlobalStyle } from 'styled-components';
import './fonts.css';
import { ThemeStyleType } from './index';
import './overrides.css';
import './rating.css';

const GlobalStyles = createGlobalStyle<{ theme: ThemeStyleType }>`
  html {
    box-sizing: border-box;
  }

  .hidden {
    display: none;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${({ theme }) => theme.typography.fonts.primary};
  }
  
  h1, h2, h3, h4, h5, h6, ol, ul {
    margin: 0;
    padding: 0;
  }

  ol, ul {
    list-style: none;
  }
  .rs-table-cell {
    max-width: 1100px;
  }
  .rs-picker-toggle-wrapper {
    z-index: 0;
  }
  .right .rsuite-color-picker .rsuite-color-picker-overlay {
    right: 0 !important;
    left: unset;
  }
  .darker {
    background-color: #000000;
    opacity: 0.9 !important;
  }
`;

export default GlobalStyles;
