import { Button } from 'rsuite';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { Loading } from '../../../../../shared/types';
import { integrationsSelector } from '../../../redux/integrations.slice';
import { getAppleStores } from '../../../redux/actions';
import { StoreItem } from './StoreItem';
import {
  ModalBody,
  ModalHeader,
} from '../../../../../shared/components/modal/modal-parts';
import { useAppleConnectContext } from '../../../contexts/AppleLocationContext';

const Container = styled.div`
  max-height: 450px;
`;
const LoadMoreWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  justify-content: center;
  align-items: center;
  .load-more-btn {
    max-width: 300px;
  }
`;

const ListAppleStores = ({
  onGoBack,
  resetCloseButton,
  onLocationSelected,
}: {
  onGoBack: () => void;
  resetCloseButton?: boolean;
  onLocationSelected: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const { formValues, updateSelectedBusiness } = useAppleConnectContext();
  const { appleStores, hasMoreAppleStores, loadingAppleStores } =
    useAppSelector(integrationsSelector);

  const isLoading = loadingAppleStores === Loading.PENDING;

  return (
    <>
      <ModalHeader
        onGoBackClick={onGoBack}
        title={t('reviews.import-source.apple.select-store')}
        closeButton={false}
        resetCloseButton={resetCloseButton}
      />
      <ModalBody>
        <Container>
          {appleStores.map((store, i) => (
            <StoreItem
              key={`loc-${i}`}
              thumbnail={store.logos[0].link}
              title={store.title}
              phone={store.link}
              onSelect={() => {
                updateSelectedBusiness(store);
                onLocationSelected();
              }}
            />
          ))}
          {hasMoreAppleStores && (
            <LoadMoreWrapper>
              <Button
                block
                className="load-more-btn"
                disabled={isLoading}
                appearance="subtle"
                onClick={() => {
                  dispatch(
                    getAppleStores({
                      ...formValues,
                      page: appleStores.length,
                    })
                  );
                }}
              >
                {isLoading ? t('shared.loading') : t('shared.load-more')}
              </Button>
            </LoadMoreWrapper>
          )}
        </Container>
      </ModalBody>
    </>
  );
};

export default ListAppleStores;
