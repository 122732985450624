import i18next from 'i18next';
import { Suspense, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import Preloader from './shared/components/Preloader';
import theme from './shared/theme';
import GlobalStyles from './shared/theme/global-styles';
import { getStore } from './store';
// import commonDe from './translations/de/common.json';
import commonEn from './translations/en/common.json';
// import commonFr from './translations/fr/common.json';
// import commonEs from './translations/es/common.json';
// import commonIt from './translations/it/common.json';
// import commonJa from './translations/ja/common.json';
// import commonNl from './translations/nl/common.json';
// import commonPt from './translations/pt/common.json';
// import commonTr from './translations/tr/common.json';
// import commonRo from './translations/ro/common.json';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CustomProvider } from 'rsuite';

ReactGA.initialize('UA-256150250-1');

export default function Root() {
  const [siteLocal, setSiteLocal] = useState('en');
  // const {
  //   site: { site },
  // } = getState() as any;

  useEffect(() => {
    i18next.init({
      interpolation: { escapeValue: false },
      fallbackLng: 'en',
      lng: 'en',
      resources: {
        en: {
          common: commonEn,
        },
        // fr: {
        //   common: commonFr,
        // },
        // de: {
        //   common: commonDe,
        // },
        // es: {
        //   common: commonEs,
        // },
        // it: {
        //   common: commonIt,
        // },
        // ja: {
        //   common: commonJa,
        // },
        // nl: {
        //   common: commonNl,
        // },
        // pt: {
        //   common: commonPt,
        // },
        // tr: {
        //   common: commonTr,
        // },
        // ro: {
        //   common: commonRo,
        // },
      },
    });
    // setSiteLocal(site?.locale);
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      <Provider store={getStore()}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Suspense fallback={<Preloader />}>
            <BrowserRouter>
              <I18nextProvider i18n={i18next}>
                <CustomProvider
                // locale={(locale as any)[siteLocal] || locale['en']}
                >
                  <App />
                </CustomProvider>
              </I18nextProvider>
            </BrowserRouter>
          </Suspense>
        </ThemeProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
}
